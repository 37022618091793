import featureFlags from 'constants/enums/featureFlags'

export type LDFlagSet = {
  [featureFlags.maintenanceMode]: boolean
  [featureFlags.termsOfService]: boolean
  [featureFlags.googlePlacesToken]: {
    e2e: string
    production: string
    qa: string
    testing: string
    sandbox: string
  }
  [featureFlags.redirectSubdomains]: {}
}

export enum BackgroundImageOptions {
  Original = 'original',
  Trailer = 'trailer',
  Handshake = 'handshake'
}

export interface Flags {
  magMaintenanceModeBoolean?: boolean
  magTermsOfServiceBoolean?: boolean
  ex4789ZeroMagnesZipCodeOnlyBoolean?: boolean
  magEx4957MagTermsOfServicePostApplication?: boolean
  prospectApplicationBackgroundImage?: string
}

export interface StateOfOperationInArray {
  isPrimary: boolean
  state: string
  hasNoLicenses: boolean
  license?: string | undefined
}

export interface StateOfOperation {
  [key: string]: {
    hasNoLicenses?: boolean
    licenses?: Array<string>
    removed?: boolean
  }
}

export interface ServicesArrayAsObject {
  [key: string]: boolean
}

export interface BusinessApplicant {
  name: string
  stateOfOperation: StateOfOperation
  hasNoEIN: boolean
  hasNoBankAccount: boolean
  hasNoWebsiteUrl: boolean
  address_street1: string
  address_city: string
  address_stateAbbreviation: string
  address_zipCode: string
  primaryStateOfOperation: string
}

export interface PrincipalApplicant {
  sameAsApplicant: boolean
  hasNoSSN: boolean
  firstName: string
  lastName: string
}

export interface ApplicantServices {
  [key: string]: boolean
}

export interface Applicant {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  status?: string
  business?: BusinessApplicant
  principal?: PrincipalApplicant
  services?: ApplicantServices
  hiddenFields?: Array<string>
  address_street1?: string
  address_city?: string
  address_stateAbbreviation?: string
  address_zipCode?: string
  agreeTermsApplicant?: boolean
  created?: boolean
  sponsorCode?: string
  completed?: boolean
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmTerm?: string
  utmContent?: string
}

export interface Stage {
  fields?: Array<string>
  name: string
  slug: string
  type: string
  featureFlag?: string
  step?: number
  title?: string
}

export interface UploadedFile {
  name: string
}

export interface UploadedFile {
  path: string
  type: string
  name: string
  size: number
}

export interface StageSlugs {
  applicant: string
  business: string
  businessAddressWebsite: string
  businessBankAccount: string
  businessForm: string
  businessNameDBAEIN: string
  businessServicesSales: string
  businessStateOfOperation: string
  businessStateOfOperationAddtionalStates: string
  businessStateOfOperationPrimaryState: string
  businessWebsiteEIN: string
  contactMe: string
  intro: string
  principal: string
  principalAddress: string
  principalForm: string
  principalName: string
  principalSSNDoB: string
  review: string
  status: string
}

export interface StateLicense {
  state: string
  license: string
}

export interface UtmParameters {
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmTerm: string
  utmContent: string
}

export interface SponsorCodeProperty {
  code: string
  defaultProjects: Array<string>
}

export type ApplicantId = string
export type Slug = string
export type Fields = Array<string>
export type Section = string
export type HiddenFields = Array<string>
export type License = Array<string>
export type ProjectTypeItem = [string, string]
