// @ts-nocheck
import { createMuiTheme } from '@material-ui/core/styles'

export const colors = {
  primary: '#0e619c',
  primarySolarZero: '#ED874E',
  secondary: '#edb548',
  errorRed: '#cb2431',
  iconSuccess: '#4db191',
  iconSuccessContainer: '#e8f5f1',
  warningBackground: '#FFF8E1',
  warningRed: '#c83f62',
  checkmarkYellow: '#f5bf65',
  textLinkBlue: '#0e609c',
  textEmphasisBlue: '#296fa4',
  background: '#fff',
  textDark: 'rgba(34, 42, 45, 1)',
  textLight: 'rgba(255, 255, 255, 1)',
  inactiveButtons: 'rgba(34, 42, 45, 0.15)',
  dividerLightGray: 'rgba(34, 42, 45, 0.1)',
  greenBackground: 'rgb(213, 230, 235)',
  backgroundImageAlternative: 'rgb(211, 211, 211)',
  lockedTextFieldColor: 'rgba(63, 191, 187, 0.2)',
  mosaicTeal: 'rgba(63, 191, 187, 1)',
  mosaicGold: 'rgba(248, 205, 127, 1)',
  mosaicBlue: 'rgba(80, 142, 170, 1)',
  mosaicOrange: 'rgba(237, 175, 38, 1)',
  mutedBlack: 'rgba(36, 36, 43, 0.7)',
  normalBlack: 'rgba(36, 36, 43, 1)',
  blueBorderColor: 'rgba(14, 97, 156, 0.3)',
  blackColor: 'rgba(0, 0, 0, 0.87)',
  cardFooterLightGray: '#F4F5F4',
  whiteTransparent: 'rgba(255, 255, 255, 0.5)',
  white: '#FFFFFF',
  successGreen: '#2D9D75',
  successGreenBackground: '#E7F3EF',
  linkGreen: '#289471',
  loadingGrayText: '#686868',
  loadingGrayProgress: '#d9d9d9'
}

export const headerHeight = 55

export default (flags, isSolarZero) =>
  createMuiTheme({
    overrides: {
      MuiInput: {
        root: {
          disabled: {
            '$&:before': {
              borderBottom: '1px dashed rgba(0, 0, 0, 0.3) !important'
            }
          }
        }
      },
      MuiInputBase: {
        root: {
          fontSize: '1rem'
        }
      },
      MuiFormControlLabel: {
        label: {
          paddingTop: 12
        },
        root: {
          alignItems: 'start'
        }
      },
      MuiFormLabel: {
        root: {
          fontSize: '1rem',
          color: colors.mutedBlack
        }
      }
    },
    direction: 'ltr',
    palette: {
      common: {
        black: colors.normalBlack,
        realBlack: colors.blackColor,
        white: colors.background,
        teal: colors.mosaicTeal,
        successGreen: colors.iconSuccess
      },
      type: 'light',
      primary: {
        light: '#64b5f6',
        main: isSolarZero ? colors.primarySolarZero : colors.primary,
        contrastText: colors.background
      },
      secondary: {
        light: '#fcf1b9',
        main: colors.secondary,
        dark: '#e7aa35',
        contrastText: colors.background
      },
      error: {
        light: '#e57373',
        main: colors.errorRed,
        dark: '#d32f2f',
        contrastText: colors.background
      },
      grey: {
        '50': '#fafafa',
        '100': '#f5f5f5',
        '200': '#eeeeee',
        '300': '#e0e0e0',
        '400': '#bdbdbd',
        '500': '#9e9e9e',
        '600': '#757575',
        '700': '#616161',
        '800': '#424242',
        '900': '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161'
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
      text: {
        primary: colors.normalBlack,
        secondary: colors.mutedBlack,
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)'
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      iconSuccess: colors.iconSuccess,
      iconSuccessContainer: colors.iconSuccessContainer,
      background: {
        paper: colors.background,
        default: '#fafafa',
        secondary: colors.greenBackground,
        backgroundImageAlternative: colors.backgroundImageAlternative,
        lockedTextFieldColor: colors.lockedTextFieldColor,
        warning: colors.warningBackground
      },
      action: {
        active: colors.mutedBlack,
        hover: 'rgba(0, 0, 0, 0.14)',
        selected: 'rgba(0, 0, 0, 0.08)',
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
        actionTextColor: colors.warningRed,
        textLinkColor: colors.primary,
        textEmphasisColor: colors.textEmphasisBlue,
        checkmarkColor: colors.checkmarkYellow,
        link: 'rgb(22, 98, 154)',
        startButton: colors.blueBorderColor
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",Helvetica,Arial,sans-serif',
      fontSize: 14,
      fontSizeSmaller: '0.65rem',
      fontWeightLight: 100,
      fontWeightRegular: 300,
      fontWeightMedium: 400,
      fontWeightBold: 500,
      fontWeightExtraBold: 600,
      h1: {
        fontFamily: 'Lora',
        fontSize: '7rem',
        fontWeight: 100,
        letterSpacing: '-.04em',
        lineHeight: '1.14286em',
        marginLeft: '-.06em',
        color: colors.mutedBlack
      },
      h2: {
        fontFamily: 'Lora',
        fontSize: '3.5rem',
        fontWeight: 300,
        letterSpacing: '-.02em',
        lineHeight: '1.30357em',
        marginLeft: '-.04em',
        color: colors.mutedBlack
      },
      h3: {
        fontFamily: 'Lora',
        fontSize: '2.8125rem',
        fontWeight: 100,
        lineHeight: '1.06667em',
        marginLeft: '-.04em',
        color: colors.mutedBlack
      },
      h4: {
        fontFamily: 'Lora',
        fontSize: '2.125rem',
        fontWeight: 300,
        lineHeight: '1.20588em',
        marginLeft: '-.04em',
        color: colors.mutedBlack
      },
      h5: {
        fontFamily: 'Lora',
        fontSize: '1.5rem',
        fontWeight: 300,
        lineHeight: '1.35417em',
        color: colors.normalBlack
      },
      h6: {
        fontFamily: 'Lora',
        fontSize: '1.3125rem',
        fontWeight: 500,
        lineHeight: '1.16667em',
        color: colors.normalBlack
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.5em',
        color: colors.normalBlack
      },
      accent: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.71429em',
        color: colors.secondary
      },
      body1: {
        fontSize: '0.875rem',
        //    fontWeight: 600,
        fontWeight: 400,
        lineHeight: '1.46429em',
        color: colors.normalBlack
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.46429em',
        color: colors.normalBlack
      },
      caption: {
        fontSize: '0.75rem',
        fontWeight: 300,
        lineHeight: '1.375em',
        color: colors.mutedBlack
      },
      tab: {
        fontSize: '0.8rem'
      },
      buttonLarge: {
        paddingLeft: 34,
        paddingRight: 34,
        height: 54,
        borderRadius: 27,
        boxShadow: 'none',
        minWidth: 180
      },
      buttonLabelLarge: {
        fontSize: 14,
        fontWeight: 600,
        opacity: 0.95,
        color: 'white',
        letterSpacing: '0.025em'
      },
      buttonPrimary: {
        paddingLeft: 32,
        paddingRight: 32,
        height: 42,
        borderRadius: 21,
        minWidth: 88,
        boxShadow: 'none',
        // iPhone 5 support for screens like Customer where primary button is located horizontally aligned
        '@media screen and (max-width: 330px)': {
          paddingLeft: 20,
          paddingRight: 20
        }
      },
      buttonLabelPrimary: {
        color: 'inherit',
        fontSize: 13,
        fontWeight: 600,
        opacity: 0.9,
        letterSpacing: '0.025em'
      },
      buttonSecondary: {
        paddingLeft: 24,
        paddingRight: 24,
        height: 40,
        borderRadius: 20,
        boxShadow: 'none',
        border: `1px solid ${colors.blueBorderColor}`
      },
      buttonLabelSecondary: {
        fontSize: 13,
        fontWeight: 600,
        opacity: 0.9,
        letterSpacing: '0.025em',
        color: colors.primary
      },
      buttonLabelNormal: {
        fontSize: 15,
        textTransform: 'capitalize',
        fontWeight: 600,
        opacity: 0.9,
        color: colors.primary
      },
      buttonTertiary: {
        padding: '0 16px',
        margin: 6,
        height: 30,
        minHeight: 'inherit',
        borderRadius: 15,
        boxShadow: 'none',
        border: `1px solid ${colors.blueBorderColor}`
      },
      buttonLabelTertiary: {
        fontSize: 11,
        fontWeight: 600,
        opacity: 0.9,
        letterSpacing: '0.025em',
        color: colors.primary
      },
      greenText: {
        color: 'rgb(73, 155, 121)'
      },
      iconSuccess: {
        color: colors.iconSuccess,
        width: 28,
        height: 28,
        marginTop: 3
      },
      iconSuccessContainer: {
        backgroundColor: colors.iconSuccessContainer,
        width: 70,
        height: 70,
        borderRadius: 35
      },
      iconStar: {
        color: colors.iconSuccess,
        width: 70,
        height: 70,
        marginTop: 3
      },
      iconStarContainer: {
        backgroundColor: 'transparent',
        width: 70,
        height: 70,
        borderRadius: 35
      }
    },
    mixins: {
      toolbar: {
        minHeight: 56,
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: 48
        },
        '@media (min-width:600px)': {
          minHeight: 64
        }
      }
    },
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    shadows: [
      'none',
      '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
      '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
      '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
      '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
      '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
      '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
      '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
      '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
      '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
      '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
      '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
      '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
      '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
      '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
    ],
    transitions: {
      easing: {
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
      },
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195
      }
    },
    spacing: 8,
    zIndex: {
      mobileStepper: 1000,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500
    }
  })
