const ldFlags = {
  maintenanceMode: 'mag.maintenance-mode.boolean',
  termsOfService: 'mag-terms-of-service-boolean',
  salesforceUrl: 'ix-97.salesforce-url-for-prospect-ui.string',
  googlePlacesToken: 'ex-6636.google-places-api-keys.json',
  redirectSubdomains: 'ix-3.redirect-prospect-ui-subdomains-to-paths.json',
  servicesOfferedForReferralCode:
    'ix-82.services-offered-for-sponsor-code.json',
  prospectApplicationBackgroundImage:
    'ao-73.prospect-application-background-image.string',
  prospectFullStory: 'atg-156.prospect-full-story.boolean',
  prospectDataDog: 'atg-642.prospect-data-dog.boolean'
}

export default ldFlags
