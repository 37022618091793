import merge from 'lodash/merge'

const sfToMagnesSchema = {
  APPLICANT_FIRSTNAME: 'mg_Applicant_First_Name__c',
  APPLICANT_LASTNAME: 'mg_Applicant_Last_Name__c',
  APPLICANT_EMAIL: 'Email',
  APPLICANT_PHONE: 'Phone',
  APPLICANT_COMPANY: 'Company',
  APPLICANT_STATUS: 'Status',
  APPLICANT_STREET: 'Street',
  APPLICANT_CITY: 'City',
  APPLICANT_STATE: 'StateCode',
  APPLICANT_POSTAL_CODE: 'PostalCode',
  APPLICANT_COUNTRY: 'Country',
  APPLICANT_WEBSITE: 'Website',
  APPLICANT_HAS_NO_WEBSITE: 'mg_Unavailable_Website__c',
  APPLICANT_ALL_BUSINESS_NAMES: 'All_Business_Names__c',
  APPLICANT_PAYFIN_PV_REVENUE: 'Payfin_PV_Revenue__c',
  APPLICANT_PRIMARY_CONTRACTOR_LICENSE: 'Primary_Contractor_License__c',
  APPLICANT_STATE_CONTRACTOR_LICENSE: 'State_Contractor_License__c',
  APPLICANT_ONLY_IN_PRIMARY_STATE: 'mg_Business_Only_in_Primary_State__c',
  APPLICANT_BUSINESS_STATE_HAS_NO_LICENSES: 'mg_No_License_Number__c',
  APPLICANT_MG_SUBMITTED: 'mg_Submitted__c',
  TOS_DOC: 'ToS_Doc',
  APPLICANT_HAS_NO_EIN: 'mg_Unavailable_EIN__c',
  APPLICANT_MG_FEDERAL_TAX_ID: 'mg_Federal_Tax_ID__c',
  APPLICANT_MG_DATE_OF_BIRTH: 'mg_DOB__c',
  FIRSTNAME: 'FirstName',
  LASTNAME: 'LastName',
  APPLICANT_MG_PRINCIPAL_EMAIL: 'mg_Business_Owner_Email__c',
  APPLICANT_MG_PRINCIPAL_STREET: 'mg_Principal_Street__c',
  APPLICANT_MG_PRINCIPAL_CITY: 'mg_Principal_City__c',
  APPLICANT_PRINCIPAL_COUNTRY_CODE: 'mg_Principal_CountryCode__c',
  APPLICANT_MG_PRINCIPAL_STATE_CODE: 'mg_Principal_StateCode__c',
  APPLICANT_MG_PRINCIPAL_POSTAL_CODE: 'mg_Principal_PostalCode__c',
  APPLICANT_MG_PRINCIPAL_PHONE: 'Business_Phone__c',
  APPLICANT_MG_REFERRAL_CODE: 'mg_ReferralCode__c',
  APPLICANT_MG_REFERRED_BY_AUTO_APPROVED_PARTNER:
    'mg_Referred_by_Auto_Approved_Partner__c',
  APPLICANT_MG_EVERGREEN_LINK: 'mg_evergreenlink__c',
  APPLICANT_HAS_NO_SSN: 'mg_Unavailable_SSN__c',
  APPLICANT_MG_SSN: 'mg_SSN__c',
  APPLICANT_MG_CONTRACTOR_LICENSES: 'mg_Contractor_Licenses__r',
  APPLICANT_MG_CONTRACTOR_LICENSES_RECORDS: 'records',
  APPLICANT_MG_CONTRACTOR_LICENSES_RECORDS_LICENSE_NUMBER:
    'mg_License_Number__c',
  APPLICANT_MG_CONTRACTOR_LICENSES_RECORDS_STATE: 'mg_State__c',
  APPLICANT_MG_CONTRACTOR_LICENSES_RECORDS_IS_PRIMARY: 'mg_IsPrimary__c',
  APPLICANT_MG_CONTRACTOR_HAS_NO_LICENSES: 'mg_No_License_Number__c',
  APPLICANT_MG_SAME_AS_OWNER: 'mg_Applicant_Same_As_Owner__c',
  HIDDEN_FIELDS: 'hiddenFields',
  ID: 'id',
  APPLICANT_ID: 'Id',
  APPLICANT_UPLOADED_FILES: 'uploadedFiles',
  APPLICANT_UPLOADED_FILE_NAME: 'Descriptive_Name__c',
  APPLICANT_ROUTING_NUMBER: 'mg_Partner_ACH_Routing__c',
  APPLICANT_ACCOUNT_ACCOUNT_NUMBER: 'mg_Partner_ACH_Account__c',
  APPLICANT_ACCOUNT_TYPE: 'mg_Partner_ACH_Type__c',
  APPLICANT_HAS_NO_BANK_ACCOUNT: 'mg_Unavailable_Bank_Account__c',
  APPLICANT_P2XURL: 'P2Xurl__c',
  APPLICANT_AGREEMENT_DATE: 'Agreement_Date__c',
  APPLICANT_AGREEMENT_VERSION: 'Agreement_Version__c',
  APPLICANT_UTM_SOURCE: 'utm_source__c',
  APPLICANT_UTM_MEDIUM: 'utm_medium__c',
  APPLICANT_UTM_CAMPAIGN: 'utm_campaign__c',
  APPLICANT_UTM_TERM: 'utm_term__c',
  APPLICANT_UTM_CONTENT: 'utm_content__c'
}

const getSfToMagnesSchema = () => {
  let servicesValue: string

  servicesValue = 'Services_Offered__c'

  return merge(sfToMagnesSchema, {
    APPLICANT_HOME_IMPROVEMENTS: servicesValue,
    APPLICANT_PRIMARY_BUSINESS: 'Primary_Service_Offered__c'
  })
}

export default getSfToMagnesSchema
