import React from 'react'
import { LDFlagSet } from './types'

type Props = {
  flags: LDFlagSet
  clientSideID: string
}

export default ({ flags }: Props) => (WrappedComponent: any) =>
  class extends React.Component {
    render() {
      return <WrappedComponent flags={flags} {...this.props} />
    }
  }
