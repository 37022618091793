enum featureFlags {
  maintenanceMode = 'magMaintenanceModeBoolean',
  termsOfService = 'magTermsOfServiceBoolean',
  salesforceUrl = 'ix97SalesforceUrlForProspectUiString',
  googlePlacesToken = 'ex6636GooglePlacesApiKeysJson',
  redirectSubdomains = 'ix3RedirectProspectUiSubdomainsToPathsJson',
  servicesOfferedForReferralCode = 'ix82ServicesOfferedForSponsorCodeJson',
  backgroundImageOption = 'ao73ProspectApplicationBackgroundImageString',
  fullStoryEnabled = 'atg156ProspectFullStoryBoolean',
  dataDogEnabled = 'atg642ProspectDataDogBoolean'
}

export default featureFlags
