import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

import coreScreenStyles from 'themes/coreScreenStyles'

const styles = theme => ({
  ...coreScreenStyles(theme)
})

interface Props {
  classes: any
  block: boolean
}

class Loading extends React.Component<Props> {
  static defaultProps = {
    block: false
  }

  render() {
    const { classes, block } = this.props
    return (
      <CircularProgress
        className={
          block ? classes.circularProgressBlock : classes.circularProgress
        }
        //@ts-ignore
        tabIndex="-1"
      />
    )
  }
}

export default withStyles(styles)(Loading)
