import { currentEnvironment, isProduction } from 'properties/properties'

const googleReCaptcha = '6Ld_ypMUAAAAACs4kQc9GiDYzHAZm_-ZHrgSXBaQ'
const googleReCaptchaTest = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'

const launchDarklyKeyTestingAndQA = '5c144425542771173690c73c'
const launchDarklyKeyE2E = '5c59d78fda31e833f56bb0f0'
const launchDarklyKeyProduction = '5c144425542771173690c73d'
const launchDarklyKeySandbox = '5f3447122c3060096ead0362'

const launchDarklyKeys = {
  testing: launchDarklyKeyTestingAndQA,
  qa: launchDarklyKeyTestingAndQA,
  e2e: launchDarklyKeyE2E,
  production: launchDarklyKeyProduction,
  sandbox: launchDarklyKeySandbox
}

export default {
  googleReCaptcha: isProduction ? googleReCaptcha : googleReCaptchaTest,
  launchDarkly: launchDarklyKeys[currentEnvironment],
  threatMetrix: '7q4ecvsx'
}
