import keys from 'lodash/keys'
import get from 'lodash/get'
import values from 'lodash/values'
import merge from 'lodash/merge'
import applyFields from 'constants/enums/applyFields'
import { StateOfOperation, Applicant } from 'types'

const stateIsPrimary = (
  newPrimaryState: string,
  stateOfOperationsObject: StateOfOperation,
  savedApplicant: Applicant,
  state: string
): boolean =>
  (!newPrimaryState && keys(stateOfOperationsObject).length === 1) ||
  (keys(stateOfOperationsObject).length > 1 &&
    state ===
      get(savedApplicant, applyFields.businessPrimaryStateOfOperation, null))

const removePreviousPrimaryState = (
  previousSavedStateOfOperations: StateOfOperation,
  newPrimaryState: string,
  savedApplicant: Applicant
): Array<StateOfOperation> => {
  const previousSavedStateOfOperationsWithoutPrimary = keys(
    previousSavedStateOfOperations
  ).reduce((result: Array<StateOfOperation>, stateAbbreviation: string) => {
    if (
      newPrimaryState === stateAbbreviation ||
      stateAbbreviation !==
        get(savedApplicant, applyFields.businessPrimaryStateOfOperation)
    ) {
      const stateObj: StateOfOperation | {} = {}
      stateObj[stateAbbreviation] =
        previousSavedStateOfOperations[stateAbbreviation]

      result.push(stateObj)
    }
    return result
  }, [])

  return previousSavedStateOfOperationsWithoutPrimary
}

const stateOfOperationsToArray = (
  stateOfOperations: Array<StateOfOperation>
): Array<StateOfOperation> =>
  keys(stateOfOperations).map(key => {
    const stateOfOperationObj: StateOfOperation | {} = {}
    stateOfOperationObj[key] = stateOfOperations[key]
    return stateOfOperationObj
  })

const getNotRemovedStateOfOperations = (
  stateOfOperationsArray: Array<StateOfOperation>
): Array<StateOfOperation> =>
  stateOfOperationsArray.filter(item => !values(item)[0].removed)

const stateOfOperationsArrayToObject = (
  stateOfOperationsArray: Array<StateOfOperation>
): StateOfOperation =>
  stateOfOperationsArray.reduce((result, item) => {
    merge(result, item)
    return result
  }, {})

const ApplicantContainerHelper = {
  stateIsPrimary,
  getNotRemovedStateOfOperations,
  removePreviousPrimaryState,
  stateOfOperationsToArray,
  stateOfOperationsArrayToObject
}
export default ApplicantContainerHelper
