import merge from 'lodash/merge'
import applyFields from 'constants/enums/applyFields'

const sfSchema = {
  [applyFields.applicantBusinessName]: 'applicant.Company',
  [applyFields.newToFinancing]: 'applicant.New_To_Financing__c',
  [applyFields.firstName]: 'applicant.mg_Applicant_First_Name__c',
  [applyFields.lastName]: 'applicant.mg_Applicant_Last_Name__c',
  [applyFields.email]: 'applicant.Email',
  [applyFields.phone]: 'applicant.Phone',
  // @ts-ignore
  [applyFields.tosDoc]: 'applicant.ToS_Doc',
  [applyFields.sponsorCode]: 'applicant.mg_ReferralCode__c',
  [applyFields.tmxSessionId]: 'thmxId',
  zero: 'applicant.PS_Zero__c',
  [applyFields.address_street1]: 'applicant.Street',
  [applyFields.address_city]: 'applicant.City',
  [applyFields.address_stateAbbreviation]: 'applicant.StateCode',
  [applyFields.address_zipCode]: 'applicant.PostalCode',
  [applyFields.businessName]: 'applicant.Company',
  [applyFields.businessHasNoEIN]: 'applicant.mg_Unavailable_EIN__c',
  [applyFields.businessTaxpayerIdentificationNumber]:
    'applicant.mg_Federal_Tax_ID__c',
  [applyFields.businessStateOfOperation]:
    'applicant.mg_Contractor_Licenses__r.records',
  [applyFields.businessAddress_street1]: 'applicant.Street',
  [applyFields.businessAddress_city]: 'applicant.City',
  [applyFields.businessDoingBusinessAs]: 'applicant.All_Business_Names__c',
  [applyFields.businessAddress_stateAbbreviation]: 'applicant.StateCode',
  [applyFields.businessAddress_zipCode]: 'applicant.PostalCode',
  [applyFields.businessAnnualSales]: 'applicant.Payfin_PV_Revenue__c',
  [applyFields.businessHasNoWebsiteUrl]: 'applicant.mg_Unavailable_Website__c',
  [applyFields.businessWebsiteUrl]: 'applicant.Website',
  [applyFields.businessBankAccountAccountNumber]:
    'applicant.mg_Partner_ACH_Account__c',
  [applyFields.businessBankAccountRoutingNumber]:
    'applicant.mg_Partner_ACH_Routing__c',
  [applyFields.businessBankAccountAccountType]:
    'applicant.mg_Partner_ACH_Type__c',
  [applyFields.businessHasNoBankAccount]:
    'applicant.mg_Unavailable_Bank_Account__c',
  [applyFields.principalFirstName]: 'applicant.FirstName',
  [applyFields.principalLastName]: 'applicant.LastName',
  [applyFields.principalEmail]: 'applicant.mg_Business_Owner_Email__c',
  [applyFields.principalPhone]: 'applicant.Business_Phone__c',
  [applyFields.principalHasNoSSN]: 'applicant.mg_Unavailable_SSN__c',
  [applyFields.principalSSN]: 'applicant.mg_SSN__c',
  [applyFields.principalDateOfBirth]: 'applicant.mg_DOB__c',
  [applyFields.principalAddressStreet]: 'applicant.mg_Principal_Street__c',
  [applyFields.principalAddressCity]: 'applicant.mg_Principal_City__c',
  [applyFields.principalAddressStateAbbreviation]:
    'applicant.mg_Principal_StateCode__c',
  [applyFields.principalAddressZipCode]: 'applicant.mg_Principal_PostalCode__c',
  [applyFields.dateSubmitted]: 'applicant.mg_Submitted__c',
  [applyFields.principalSameAsApplicant]:
    'applicant.mg_Applicant_Same_As_Owner__c',
  //[applyFields.uploadedFiles]: 'applicant.uploadedFiles.records',
  [applyFields.utmSource]: 'applicant.utm_source__c',
  [applyFields.utmMedium]: 'applicant.utm_medium__c',
  [applyFields.utmCampaign]: 'applicant.utm_campaign__c',
  [applyFields.utmTerm]: 'applicant.utm_term__c',
  [applyFields.utmContent]: 'applicant.utm_content__c'
}

const getSfSchema = () => {
  const schema = sfSchema
  let servicesValue: string

  servicesValue = 'applicant.Services_Offered__c'

  return merge(schema, {
    [applyFields.services]: servicesValue,
    [applyFields.primaryBusiness]: 'applicant.Primary_Service_Offered__c'
  })
}

export default getSfSchema
