import { has, isUndefined } from 'lodash'
import applyFields from 'constants/enums/applyFields'
import stageSlugs from 'constants/enums/stageSlugs'
import stageTypes from 'constants/enums/stageTypes'
import l10n from 'properties/translations'
import { Flags, Stage, Fields } from 'types'

const applicantFields: Fields = [
  applyFields.firstName,
  applyFields.lastName,
  applyFields.email,
  applyFields.phone,
  applyFields.agreeTermsApplicant,
  applyFields.sponsorCode,
  applyFields.created,
  applyFields.services,
  applyFields.primaryBusiness,
  applyFields.address_zipCode,
  applyFields.address_street1,
  applyFields.address_city,
  applyFields.address_stateAbbreviation,
  applyFields.applicantBusinessName
]

const businessFields: Fields = [
  applyFields.businessName,
  applyFields.businessDoingBusinessAs,
  applyFields.services,
  applyFields.businessAnnualSales,
  applyFields.businessWebsiteUrl,
  applyFields.businessHasNoWebsiteUrl,
  applyFields.businessTaxpayerIdentificationNumber,
  applyFields.businessHasNoEIN,
  applyFields.businessStateOfOperation,
  applyFields.businessPrimaryStateOfOperation,
  applyFields.businessBankAccountAccountNumber,
  applyFields.businessBankAccountRoutingNumber,
  applyFields.businessBankAccountAccountType,
  applyFields.businessHasNoBankAccount,
  applyFields.businessUploadedFiles,
  applyFields.businessAddress_street1,
  applyFields.businessAddress_city,
  applyFields.businessAddress_stateAbbreviation,
  applyFields.businessAddress_zipCode,
  applyFields.applicantBusinessName
]

const stages = (flags: Flags): Array<Stage> => [
  {
    name: l10n.desktopStages.welcome.name,
    type: stageTypes.intro,
    slug: stageSlugs.intro
  },
  {
    name: l10n.desktopStages.applicant.name,
    title: l10n.desktopStages.applicant.title,
    type: stageTypes.form,
    slug: stageSlugs.applicant,
    fields: applicantFields
  },
  {
    name: l10n.desktopStages.contactMe.name,
    type: stageTypes.contactMe,
    slug: stageSlugs.contactMe
  },
  {
    name: l10n.desktopStages.businessIntro.name,
    type: stageTypes.intro,
    slug: stageSlugs.business
  },
  {
    name: l10n.desktopStages.businessForm.name,
    title: l10n.desktopStages.businessForm.title,
    type: stageTypes.form,
    slug: stageSlugs.businessForm,
    fields: businessFields
  },
  {
    name: l10n.desktopStages.principalIntro.name,
    type: stageTypes.intro,
    slug: stageSlugs.principal
  },
  {
    name: l10n.desktopStages.principalForm.name,
    title: l10n.desktopStages.principalForm.title,
    type: stageTypes.form,
    slug: stageSlugs.principalForm,
    fields: [
      applyFields.principalFirstName,
      applyFields.principalLastName,
      applyFields.principalEmail,
      applyFields.principalPhone,
      applyFields.principalSameAsApplicant,
      applyFields.principalAddressStreet,
      applyFields.principalAddressCity,
      applyFields.principalAddressStateAbbreviation,
      applyFields.principalAddressZipCode,
      applyFields.principalSSN,
      applyFields.principalHasNoSSN,
      applyFields.principalDateOfBirth
    ]
  },
  {
    name: l10n.desktopStages.reviewAndSubmit.name,
    type: stageTypes.review,
    slug: stageSlugs.review
  },
  {
    name: l10n.desktopStages.status.name,
    title: l10n.desktopStages.status.title,
    type: stageTypes.status,
    slug: stageSlugs.status
  },
  {
    name: l10n.phoneStages.termsOfService.name,
    title: l10n.phoneStages.termsOfService.name,
    type: stageTypes.status,
    slug: stageSlugs.status
  }
]

export default (ldFlags: Flags): Array<Stage> =>
  stages(ldFlags).filter(
    stage =>
      ldFlags &&
      (!has(stage, 'featureFlag') ||
        (!isUndefined(stage.featureFlag) && ldFlags[stage.featureFlag]))
  )
