import {
  isArray,
  isEmpty,
  isEqual,
  isMatch,
  keys as lodashKeys,
  values as lodashValues,
  merge,
  mergeWith,
  isUndefined,
  get,
  mapKeys,
  noop,
  omit,
  omitBy,
  set
} from 'lodash'
import { Container } from 'unstated'
import client from 'store/api/client'
import applyFields from 'constants/enums/applyFields'
import progressStatuses from 'constants/enums/progressStatuses'
import l10n from 'properties/translations'
import validations from 'properties/validations'
import { slugToPath } from 'utils/routeHelper'
import { progressContainer } from 'store/ProgressContainer'
import { stagesContainer } from 'store/StagesContainer'
import mapHelper from 'utils/mapHelper'
import getSfSchema from 'constants/enums/sfSchema'
import mapErrorHelper from 'utils/mapErrorHelper'
import transformSalesforceToMagnesAPI from 'utils/applicantHelper'
import applicantContainerHelper from 'store/ApplicantContainerHelper'
import sfOnlyRequestFields from 'constants/enums/sfOnlyRequestFields'
import { isSolarZero } from 'utils/sessionHelper'
import * as apiRoutes from 'constants/enums/apiRoutes'
import {
  Flags,
  StateOfOperation,
  Applicant,
  ServicesArrayAsObject,
  StateOfOperationInArray,
  ApplicantId,
  Slug,
  UtmParameters
} from 'types'

type CurrentStateOfOperation = [
  string,
  {
    licenses: Licenses
    hasNoLicenses: boolean
  }
]

type Licenses = Array<string>
type Services = Array<string>

const sfSchemaZeroFilter = (flags: Flags): Object =>
  isSolarZero() ? getSfSchema() : omit(getSfSchema(), 'zero')

const stateOfOperationArrayToObject = (
  stateOfOperations: Array<StateOfOperationInArray>
): StateOfOperation => {
  const initialStateOfOperation: StateOfOperation | {} = {}
  return stateOfOperations.reduce(
    (result, { license, state, hasNoLicenses = true }) => {
      if (!result[state])
        // eslint-disable-next-line no-param-reassign
        result[state] = {
          hasNoLicenses,
          licenses: []
        }

      if (license) {
        result[state].licenses.push(license)
      }

      return result
    },
    initialStateOfOperation
  )
}

const servicesArrayToObject = (services: Services): ServicesArrayAsObject =>
  services.reduce((result, service) => {
    // eslint-disable-next-line no-param-reassign
    result[service] = true

    return result
  }, {})

const transforms = {
  [applyFields.services]: {
    from: services => servicesArrayToObject(services),
    to: services =>
      Object.entries(services)
        .filter(([, value]) => !!value)
        .map(([key]) => key)
  },
  [applyFields.businessStateOfOperation]: {
    from: stateOfOperations => stateOfOperationArrayToObject(stateOfOperations),

    to: (
      stateOfOperations: Array<StateOfOperation>,
      primaryState: string,
      savedApplicant: Applicant
    ): Array<Object> => {
      let previousSavedStateOfOperations = get(
        savedApplicant,
        'business.stateOfOperation',
        {}
      )

      // This removes the previous saved primary state and adds the new one
      if (primaryState) {
        const stateKeys = lodashKeys(previousSavedStateOfOperations)

        if (stateKeys.length > 0) {
          const previousSavedStateOfOperationsWithoutPrimary = applicantContainerHelper.removePreviousPrimaryState(
            previousSavedStateOfOperations,
            primaryState,
            savedApplicant
          )

          previousSavedStateOfOperations = previousSavedStateOfOperationsWithoutPrimary.reduce(
            (result, item) => {
              merge(result, item)
              return result
            },
            {}
          )
        }
      }

      // eslint-disable-next-line no-param-reassign
      stateOfOperations = merge(
        previousSavedStateOfOperations,
        stateOfOperations
      )

      let stateOfOperationsArray = applicantContainerHelper.stateOfOperationsToArray(
        stateOfOperations
      )

      stateOfOperationsArray = applicantContainerHelper.getNotRemovedStateOfOperations(
        stateOfOperationsArray
      )

      const stateOfOperationsObject: StateOfOperation = applicantContainerHelper.stateOfOperationsArrayToObject(
        stateOfOperationsArray
      )

      const stateOfOperationArray: Array<
        StateOfOperationInArray
      > = Object.entries(stateOfOperationsObject).reduce(
        (
          result,
          [state, { licenses = [], hasNoLicenses }]: CurrentStateOfOperation
        ) => {
          let isPrimary = primaryState === state

          // If there's only one state it means that's the primary and if there are more than one and they
          // aren't the prymary it means these are 'additional states'
          if (
            applicantContainerHelper.stateIsPrimary(
              primaryState,
              stateOfOperationsObject,
              savedApplicant,
              state
            )
          ) {
            isPrimary = true
          }

          let operations: Array<StateOfOperationInArray> | [] = []

          if (hasNoLicenses) {
            operations = (operations as StateOfOperationInArray[]).concat({
              isPrimary,
              state,
              hasNoLicenses
            })
          }

          if (!hasNoLicenses && licenses.length) {
            operations = (licenses as any[]).map(license => {
              if (license) {
                return {
                  isPrimary,
                  license,
                  state,
                  hasNoLicenses
                }
              }
              return {
                isPrimary,
                state,
                hasNoLicenses
              }
            })
          }

          return (result as StateOfOperationInArray[]).concat(operations)
        },
        []
      )

      return stateOfOperationArray
    },
    relatedField: applyFields.businessPrimaryStateOfOperation
  },
  [applyFields.businessPrimaryStateOfOperation]: {
    from: (_, stateOfOperations) => {
      const { state } = stateOfOperations.find(
        ({ isPrimary }) => isPrimary
      ) || { state: '' }

      return state
    },
    to: state => state || null,
    relatedField: applyFields.businessStateOfOperation
  },
  [applyFields.businessTaxpayerIdentificationNumber]: {
    from: (ein: string): string => ein,
    to: (ein: string, hasNoEIN: boolean): string | null =>
      !hasNoEIN ? ein : null,
    relatedField: applyFields.businessHasNoEIN
  },
  [applyFields.businessWebsiteUrl]: {
    from: (url: string): string => url,
    to: (url: string, hasNoWebsiteUrl: boolean): string | null =>
      !hasNoWebsiteUrl ? url : null,
    relatedField: applyFields.businessHasNoWebsiteUrl
  },
  [applyFields.principalFirstName]: {
    from: (firstName: string): string => firstName,
    to: (firstName: string, sameAsApplicant: boolean): string | undefined =>
      !sameAsApplicant ? firstName : undefined,
    relatedField: applyFields.principalSameAsApplicant
  },
  [applyFields.principalLastName]: {
    from: (lastName: string): string => lastName,
    to: (lastName: string, sameAsApplicant: boolean): string | undefined =>
      !sameAsApplicant ? lastName : undefined,
    relatedField: applyFields.principalSameAsApplicant
  },
  [applyFields.principalEmail]: {
    from: (email: string): string => email,
    to: (email: string, sameAsApplicant: boolean): string | undefined =>
      !sameAsApplicant ? email : undefined,
    relatedField: applyFields.principalSameAsApplicant
  },
  [applyFields.principalPhone]: {
    from: (phone: string): string => phone,
    to: (phone: string, sameAsApplicant: boolean): string | undefined =>
      !sameAsApplicant ? phone : undefined,
    relatedField: applyFields.principalSameAsApplicant
  },
  [applyFields.principalSSN]: {
    from: (ssn: string): string => ssn,
    to: (ssn: string, hasNoSSN: boolean): string | null =>
      !hasNoSSN ? ssn : null,
    relatedField: applyFields.principalHasNoSSN
  },
  [applyFields.businessUploadedFiles]: {
    from: (uploadedFiles: Array<string>): Array<string> => uploadedFiles,
    to: (uploadedFiles: Array<string>): Array<string> => uploadedFiles || [],
    relatedField: applyFields.businessUploadedFiles
  }
}

const copyArraysCustomizer: any = (objValue: any, srcValue: any): any => {
  if (isArray(objValue)) {
    return merge([], srcValue)
  }
}

const mergeCopyArrays: any = (...args) =>
  // @ts-ignore
  mergeWith(...args, copyArraysCustomizer)

const fieldsNotAllowedToBeSentBlank: Array<string> = [
  applyFields.firstName,
  applyFields.lastName,
  applyFields.phone,
  applyFields.email,
  applyFields.businessName
]

interface State {
  applicantId: ApplicantId | null
  applicant: Applicant | {}
  globalError: any
  errorMessages: Object
  errorValues: Object
  pendingSaveData: Applicant | {}
  savedApplicant: Applicant | {}
  showTerms: boolean
  tmxSessionId: any
  selectedState: any
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmTerm: string
  utmContent: string
  initialSponsorCode?: string
}

interface SelectedState {
  name: string
  abbreviation: string
  label: string
}

interface Err extends Error {
  data?: { error?: any; errors?: any }
  statusCode?: any
}

export default class ApplicantContainer extends Container<State> {
  state = {
    applicantId: null,
    applicant: {},
    globalError: null,
    errorMessages: {},
    errorValues: {},
    pendingSaveData: {},
    savedApplicant: {},
    showTerms: false,
    tmxSessionId: null,
    selectedState: null,
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmTerm: '',
    utmContent: '',
    initialSponsorCode: undefined
  }

  nextCard = (slug: Slug, slugToSkip: Slug | null = null): string =>
    stagesContainer.nextCard(slug, this.state.applicantId, {}, slugToSkip)

  prevCard = (slug: string, flags: Flags = {}) =>
    stagesContainer.prevCard(slug, this.state.applicantId, flags)

  slugToPath = (slug: string): string =>
    slugToPath(slug, this.state.applicantId)

  showTerms = async (): Promise<void> => {
    await this.setState({
      showTerms: true
    })
  }

  setInitialSponsorCode = async (sponsorCode: string): Promise<void> => {
    const currentSponsorCode = get(
      this.state.applicant,
      applyFields.sponsorCode
    )

    if (currentSponsorCode) return

    return new Promise(resolve => {
      this.setState(({ applicant }) => {
        const updatedApplicant = merge({}, applicant)

        set(updatedApplicant, applyFields.sponsorCode, sponsorCode)

        return {
          initialSponsorCode: sponsorCode,
          applicant: updatedApplicant
        }
      }, resolve)
    })
  }

  setUtmParams = async (parameters: UtmParameters): Promise<void> => {
    const {
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent
    } = parameters
    await this.setState({
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent
    })
  }

  getOptimisticApplicant = (): Object => {
    const { applicant, savedApplicant } = this.state

    return this.hasPendingUpdates() ? applicant : savedApplicant
  }

  hasPendingUpdates = (): boolean => {
    const { pendingSaveData } = this.state
    const { status } = progressContainer.state

    return !isEmpty(pendingSaveData) || status === progressStatuses.saving
  }

  // @todo: move to a helper
  matchApplicant = (applicant: Object, data: Object): boolean => {
    if (!isMatch(applicant, data)) return false
    const updatedStatesOfOperation = get(
      data,
      applyFields.businessStateOfOperation,
      {}
    )

    const currentStatesOfOperation = get(
      applicant,
      applyFields.businessStateOfOperation,
      {}
    )

    return isEqual(currentStatesOfOperation, updatedStatesOfOperation)
  }

  /*
   * Resolves a promise after a delay, if it's called multiple times
   * it cancels the previous timeout and start a new one, resolves
   * all the pending callbacks at the same time
   */

  delayedFunction = (finalCallback, eachCallback = noop, defaultDelay = 0) => {
    let deferResolve: Function | null = null
    let deferReject: Function | null = null
    let lastPromise: Promise<any> = Promise.resolve()
    let promise: any = null
    let timeoutId: any = null

    const callback = async (flags: Flags) => {
      promise = null
      timeoutId = null

      try {
        await finalCallback(flags)
        if (deferResolve) deferResolve()
      } catch (e) {
        if (deferReject) deferReject(e)
      }
    }

    return async (data, flags: any, delay = defaultDelay) => {
      if (!promise) {
        promise = new Promise((resolve, reject) => {
          deferResolve = resolve
          deferReject = reject
        })
      }

      const preventNewTimeout: any = await eachCallback(data, flags)

      if (!preventNewTimeout) {
        if (timeoutId) clearTimeout(timeoutId)
        timeoutId = setTimeout(() => callback(flags), delay)
      } else if (!timeoutId) {
        return lastPromise
      }

      lastPromise = promise

      return promise
    }
  }

  consolidateData = async (data: any, flags: any) => {
    if (this.matchApplicant(this.state.savedApplicant, data)) return true

    let keys = lodashValues(applyFields)

    const validData = keys.reduce((result, key) => {
      const value = get(data, key)
      const validation = get(validations, key)
      const isValid = validation.isValidSync(value)

      // Don't store empty string values, the API doesn't support it
      if (
        (isValid && value !== undefined) ||
        (!isValid &&
          value === '' &&
          value !== undefined &&
          !fieldsNotAllowedToBeSentBlank.includes(key)) ||
        // The Referral Code must always be sent, even if empty
        (isValid &&
          value !== undefined &&
          value === '' &&
          key === applyFields.sponsorCode)
      ) {
        set(result, key, value)
      }

      return result
    }, {})

    await this.setState(prevState => ({
      applicant: mergeCopyArrays(prevState.applicant, data),
      pendingSaveData: merge(prevState.pendingSaveData, validData)
    }))

    return false
  }

  save = async (flags: any): Promise<any> => {
    const { pendingSaveData } = this.state

    if (isEmpty(pendingSaveData)) return

    await this.setState({
      pendingSaveData: {}
    })

    if (this.state.applicantId) {
      // @ts-ignore
      if (pendingSaveData.completed) {
        await this.submitApplicant(pendingSaveData, flags)
        return
      }
      await this.updateApplicant(pendingSaveData, flags)
      return
    }

    await this.createApplicant(pendingSaveData, flags)
  }

  upsertApplicant = this.delayedFunction(this.save, this.consolidateData)

  transformApplicant = transformKey => data => {
    const applicant = merge({}, data)

    Object.entries(transforms).forEach(([key, transform]) => {
      const { relatedField = null } = transform
      // @ts-ignore
      const relatedFieldValue = get(data, relatedField)
      const value = get(applicant, key)

      if (relatedFieldValue === undefined && value === undefined) return
      set(
        applicant,
        key,
        transform[transformKey](value, relatedFieldValue, this.state.applicant)
      )
    })

    return applicant
  }

  fromServer = this.transformApplicant('from')

  toServer = this.transformApplicant('to')

  persistError = async (e: any, values: Object, flags: Flags) => {
    const { data = {}, status } = e.response
    let { error, errors } = mapErrorHelper(data, flags)

    if (isEmpty(error) && isEmpty(errors)) return e

    // @todo: not a global error, the API should return the errors format
    if (status === 409) {
      error = null
      errors = {
        email: l10n.apply.section1.duplicateEmailError
      }
    }

    // Global Error
    if (error) {
      await this.setState({
        globalError: {
          message: error,
          status
        }
      })
    } else {
      errors = mapKeys(errors, (value, key) => key.replace(/,/g, '.'))

      await this.setState(prevState => ({
        errorMessages: mergeCopyArrays(prevState.errorMessages, errors),
        errorValues: merge(prevState.errorValues, values)
      }))
    }

    const errorLabel =
      status >= 500 ? 'Internal Server Error' : 'Server Validation Error'
    const result: Err = new Error(errorLabel)

    result.data = {
      error,
      errors
    }

    result.statusCode = status

    return result
  }

  checkUpdates = async (data: Applicant): Promise<void> => {
    const { hiddenFields } = data

    if (hiddenFields) {
      await stagesContainer.setHiddenFields(hiddenFields)
    }
  }

  createApplicant = async (applicant: Applicant, flags: Flags) => {
    const { tmxSessionId } = this.state
    try {
      await progressContainer.setStatus(progressStatuses.saving)
      set(applicant, sfOnlyRequestFields.zero, isSolarZero())

      let { data: createData } = await client.post(
        apiRoutes.postApplicant(),
        mapHelper(
          {
            ...this.toServer(applicant),
            // @deprecated: remove when the API implement the name change
            sessionId: tmxSessionId,
            tmxSessionId
          },
          sfSchemaZeroFilter(flags)
        ),
        null,
        flags
      )

      createData = transformSalesforceToMagnesAPI(createData)
      const applicantId = createData.id

      let updateData = {}
      const restData = omitBy(applicant, (value, key) =>
        [
          applyFields.firstName,
          applyFields.lastName,
          applyFields.phone,
          applyFields.email,
          applyFields.agreeTermsApplicant,
          applyFields.recaptcha,
          applyFields.services,
          sfOnlyRequestFields.zero,
          applyFields.loanType,
          applyFields.address_city,
          applyFields.address_stateAbbreviation,
          applyFields.address_street1,
          applyFields.address_zipCode
        ].includes(key)
      )

      if (lodashKeys(restData).length) {
        const { data } = await client.patch(
          apiRoutes.patchApplicant(applicantId),
          mapHelper(
            {
              ...this.toServer(restData)
            },
            sfSchemaZeroFilter(flags)
          ),
          null,
          flags
        )

        updateData = transformSalesforceToMagnesAPI(data)
      }

      const createServerData = this.fromServer(createData)
      const updateServerData = this.fromServer(updateData)

      const serverData = merge({}, updateServerData, createServerData, {
        [applyFields.created]: true
      })

      await this.setState({
        applicantId,
        errorMessages: {},
        errorValues: {},
        savedApplicant: merge({}, applicant, serverData)
      })

      await this.checkUpdates(serverData)
    } catch (e) {
      const error = await this.persistError(e, applicant, flags)

      throw error
    } finally {
      await progressContainer.revertStatus()
    }
  }

  fetchApplicant = async (applicantId: string, flags: Flags) => {
    const { data } = await client.get(
      apiRoutes.getApplicant(applicantId),
      null,
      flags
    )
    const serverData = this.fromServer(transformSalesforceToMagnesAPI(data))
    set(serverData, applyFields.agreeTermsApplicant, true)
    set(serverData, applyFields.created, true)

    await this.setState(prevState => ({
      applicant: mergeCopyArrays({}, serverData, prevState.applicant),
      applicantId,
      savedApplicant: serverData
    }))

    await this.checkUpdates(serverData)
  }

  submitApplicant = async (savedApplicant: Applicant, flags: Flags) => {
    const { applicantId } = this.state

    try {
      await progressContainer.setStatus(progressStatuses.saving)

      const [dateSubmitted] = new Date().toISOString().split('T')

      let { data } = await client.patch(
        `/applicant/${applicantId}`,
        mapHelper(
          {
            dateSubmitted
          },
          sfSchemaZeroFilter(flags)
        ),
        null,
        flags
      )
      data = transformSalesforceToMagnesAPI(data)

      await this.setState(prevState => ({
        errorMessages: {},
        errorValues: {},
        savedApplicant: mergeCopyArrays({}, prevState.savedApplicant, data)
      }))

      await this.checkUpdates(data)
    } catch (e) {
      const error = await this.persistError(e, {}, flags)

      await this.setState(prevState => ({
        applicant: mergeCopyArrays({}, prevState.applicant, {
          completed: false
        })
      }))

      throw error
    } finally {
      await progressContainer.revertStatus()
    }
  }

  updateApplicant = async (updatedData: Applicant, flags: Flags) => {
    const {
      applicantId,
      savedApplicant,
      tmxSessionId,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent
    } = this.state

    if (this.matchApplicant(savedApplicant, updatedData)) return

    const transformedData = this.toServer(updatedData)
    const { business = {}, principal = {}, ...restData } = transformedData

    // avoid sending PATCH requests if the transform removed all the data to send
    if (
      isEmpty(omitBy(business, isUndefined)) &&
      isEmpty(omitBy(principal, isUndefined)) &&
      isEmpty(omitBy(restData, isUndefined))
    )
      return

    try {
      await progressContainer.setStatus(progressStatuses.saving)

      const dataToSfSchema = mapHelper(
        {
          ...transformedData,
          // @deprecated: remove when the API implement the name change
          sessionId: tmxSessionId,
          tmxSessionId
        },
        sfSchemaZeroFilter(flags)
      )

      if (isSolarZero()) {
        set(dataToSfSchema, getSfSchema().zero, isSolarZero())
      }

      if (isEmpty(get(transformedData, 'applicant')) && utmSource) {
        set(dataToSfSchema, getSfSchema()[applyFields.utmSource], utmSource)
        set(dataToSfSchema, getSfSchema()[applyFields.utmMedium], utmMedium)
        set(dataToSfSchema, getSfSchema()[applyFields.utmCampaign], utmCampaign)
        set(dataToSfSchema, getSfSchema()[applyFields.utmTerm], utmTerm)
        set(dataToSfSchema, getSfSchema()[applyFields.utmContent], utmContent)
      }

      const { data } = await client.patch(
        `/applicant/${applicantId}`,
        dataToSfSchema,
        null,
        flags
      )

      const serverData = this.fromServer(transformSalesforceToMagnesAPI(data))

      await this.setState(prevState => ({
        errorMessages: {},
        errorValues: {},
        savedApplicant: mergeCopyArrays(
          {},
          prevState.savedApplicant,
          updatedData,
          serverData
        )
      }))

      await this.checkUpdates(serverData)
    } catch (e) {
      const error = await this.persistError(e, updatedData, flags)

      throw error
    } finally {
      await progressContainer.revertStatus()
    }
  }

  setTmxSessionId = async (tmxSessionId: string) => {
    await this.setState({
      tmxSessionId
    })
  }

  setPrimaryState = (selectedState: SelectedState) =>
    this.setState({
      selectedState
    })

  get initialSponsorCode() {
    return this.state.initialSponsorCode
  }
}
