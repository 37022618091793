import { has, isUndefined } from 'lodash'
import applyFields from 'constants/enums/applyFields'
import stageSlugs from 'constants/enums/stageSlugs'
import stageTypes from 'constants/enums/stageTypes'
import l10n from 'properties/translations'
import { Flags, Fields, Stage } from 'types'

const applicantFields: Fields = [
  applyFields.firstName,
  applyFields.lastName,
  applyFields.email,
  applyFields.phone,
  applyFields.agreeTermsApplicant,
  applyFields.sponsorCode,
  applyFields.created,
  applyFields.services,
  applyFields.primaryBusiness,
  applyFields.address_zipCode,
  applyFields.address_street1,
  applyFields.address_city,
  applyFields.address_stateAbbreviation,
  applyFields.applicantBusinessName
]

const stages = (flags: Flags): Array<Stage> => [
  {
    name: l10n.phoneStages.welcome.name,
    type: stageTypes.intro,
    slug: stageSlugs.intro
  },
  {
    name: l10n.phoneStages.applicant.name,
    title: l10n.phoneStages.applicant.title,
    type: stageTypes.form,
    slug: stageSlugs.applicant,
    fields: applicantFields
  },
  {
    name: l10n.desktopStages.contactMe.name,
    type: stageTypes.contactMe,
    slug: stageSlugs.contactMe
  },
  {
    name: l10n.phoneStages.businessIntro.name,
    type: stageTypes.intro,
    slug: stageSlugs.business
  },
  {
    name: l10n.phoneStages.businessNameDbaEin.name,
    type: stageTypes.form,
    slug: stageSlugs.businessNameDBAEIN,
    fields: [
      applyFields.businessName,
      applyFields.businessDoingBusinessAs,
      applyFields.businessTaxpayerIdentificationNumber,
      applyFields.businessUploadedFiles
    ]
  },
  {
    name: l10n.phoneStages.businessStateOfOperationPrimaryState.name,
    type: stageTypes.form,
    slug: stageSlugs.businessStateOfOperationPrimaryState,
    fields: [
      applyFields.businessPrimaryStateOfOperation,
      applyFields.businessStateOfOperation
    ]
  },
  {
    name: l10n.phoneStages.businessAdditionaStatesOfOperation.name,
    type: stageTypes.form,
    slug: stageSlugs.businessStateOfOperationAddtionalStates,
    fields: [applyFields.businessStateOfOperation]
  },
  {
    name: l10n.phoneStages.businessAddresAndWebsiteUrl.name,
    type: stageTypes.form,
    slug: stageSlugs.businessAddressWebsite,
    fields: [applyFields.businessWebsiteUrl]
  },
  {
    name: l10n.phoneStages.businessServicesAndSales.name,
    type: stageTypes.form,
    slug: stageSlugs.businessServicesSales,
    fields: [applyFields.services, applyFields.businessAnnualSales]
  },
  {
    name: l10n.phoneStages.businessBankAccount.name,
    type: stageTypes.form,
    slug: stageSlugs.businessBankAccount,
    fields: [
      applyFields.businessBankAccountAccountNumber,
      applyFields.businessBankAccountRoutingNumber,
      applyFields.businessBankAccountAccountType,
      applyFields.businessHasNoBankAccount
    ]
  },
  {
    name: l10n.phoneStages.principalIntro.name,
    type: stageTypes.intro,
    slug: stageSlugs.principal
  },
  {
    name: l10n.phoneStages.principalName.name,
    title: l10n.phoneStages.principalName.title,
    type: stageTypes.form,
    slug: stageSlugs.principalName,
    fields: [
      applyFields.principalFirstName,
      applyFields.principalLastName,
      applyFields.principalEmail,
      applyFields.principalPhone,
      applyFields.principalSameAsApplicant
    ]
  },
  {
    name: l10n.phoneStages.principalAddress.name,
    title: l10n.phoneStages.principalAddress.title,
    type: stageTypes.form,
    slug: stageSlugs.principalAddress,
    fields: [
      applyFields.principalAddressStreet,
      applyFields.principalAddressCity,
      applyFields.principalAddressStateAbbreviation,
      applyFields.principalAddressZipCode
    ]
  },
  {
    name: l10n.phoneStages.principalSsnAndDateOfBirth.name,
    title: l10n.phoneStages.principalSsnAndDateOfBirth.title,
    type: stageTypes.form,
    slug: stageSlugs.principalSSNDoB,
    fields: [
      applyFields.principalSSN,
      applyFields.principalHasNoSSN,
      applyFields.principalDateOfBirth
    ]
  },
  {
    name: l10n.phoneStages.reviewAndSubmit.name,
    type: stageTypes.review,
    slug: stageSlugs.review
  },
  {
    name: l10n.phoneStages.status.name,
    title: l10n.phoneStages.status.title,
    type: stageTypes.status,
    slug: stageSlugs.status
  },
  {
    name: l10n.phoneStages.termsOfService.name,
    title: l10n.phoneStages.termsOfService.name,
    type: stageTypes.status,
    slug: stageSlugs.status
  }
]

export default (ldFlags: Flags): Array<Stage> =>
  stages(ldFlags).filter(
    stage =>
      ldFlags &&
      (!has(stage, 'featureFlag') ||
        (!isUndefined(stage.featureFlag) && ldFlags[stage.featureFlag]))
  )
