import React from 'react'
import { Subscribe } from 'unstated'
import ErrorMessageContainer from 'components/shared/ErrorMessageContainer'
import ApplicantContainer from 'store/ApplicantContainer'
import ProgressContainer from 'store/ProgressContainer'
import StagesContainer from 'store/StagesContainer'
import FileUploadContainer from 'store/FileUploadContainer'
import SessionContainer from 'store/SessionContainer'

export default containers => Component =>
  class extends React.Component {
    render() {
      return (
        <Subscribe to={containers}>
          {(...containersInstances) => {
            const instanceNameToClass = {
              applicantContainer: ApplicantContainer,
              progressContainer: ProgressContainer,
              stagesContainer: StagesContainer,
              fileUploadContainer: FileUploadContainer,
              sessionContainer: SessionContainer,
              errorMessageContainer: ErrorMessageContainer
            }

            const classToInstanceName = container => {
              const index = Object.values(instanceNameToClass).findIndex(
                c => c === container
              )

              const instanceNames = Object.keys(instanceNameToClass)

              return instanceNames[index]
            }

            const instancesName = containers.map(container =>
              classToInstanceName(container)
            )

            const containersProps = instancesName.reduce(
              (result, name, index) => {
                // eslint-disable-next-line no-param-reassign
                result[name] = containersInstances[index]

                return result
              },
              {}
            )

            return <Component {...containersProps} {...this.props} />
          }}
        </Subscribe>
      )
    }
  }
