import Cookies from 'js-cookie'
import { parse } from 'query-string'

import cookies from 'constants/enums/cookies'
import sessionStorageItems from 'constants/enums/sessionStorageItems'

const REFERRAL_CODE_COOKIE_EXP_DAYS = 90

export const isSolarZero = (): boolean =>
  sessionStorage.getItem(sessionStorageItems.SOLAR_ZERO_TARGETING) === 'true'

export const getReferralCode = (queryString: string): string | undefined => {
  const cookieRef = Cookies.get(cookies.referralCode)
  const { ref } = parse(queryString)

  if (ref) {
    // This strategy will set, but also extend,
    // the cookie every time someone uses the referral link
    Cookies.set(cookies.referralCode, ref.toString(), {
      expires: REFERRAL_CODE_COOKIE_EXP_DAYS
    })
    return ref.toString()
  }

  if (cookieRef) return cookieRef
}
