import { merge, pickBy } from 'lodash'
import { lowerCaseFirstChar } from 'utils/stringHelper'

export const projectTypeKeys = {
  doors: 'doors',
  interiorRemodel: 'interiorRemodel',
  windows: 'windows',
  exteriorRemodel: 'exteriorRemodel',
  roofing: 'roofing',
  hvac: 'hvac',
  solar: 'solar'
}

export const projectTypesWithFlexOn = {
  [projectTypeKeys.doors]: 'Doors',
  [projectTypeKeys.interiorRemodel]: 'InteriorRemodel',
  [projectTypeKeys.windows]: 'Windows',
  [projectTypeKeys.exteriorRemodel]: 'ExteriorRemodel',
  [projectTypeKeys.roofing]: 'Roofing',
  [projectTypeKeys.hvac]: 'Hvac',
  [projectTypeKeys.solar]: 'Solar'
}

export const getProjectTypes = () => {
  return projectTypesWithFlexOn
}

export const getCheckedServices = (servicesOffered = {}) => {
  return pickBy(servicesOffered, value => Boolean(value) === true)
}

export const getProjectTypesPrimaryBusiness = (servicesOffered = {}) => {
  const checkedServicesArr = Object.entries(getCheckedServices(servicesOffered))
  return checkedServicesArr.map(value => [
    lowerCaseFirstChar(value[0]),
    value[0]
  ])
}

export const projectTypesInThreeColumns = () => {
  const projectTypes = getProjectTypes()
  return {
    doors: projectTypes.doors,
    interiorRemodel: projectTypes.interiorRemodel,
    windows: projectTypes.windows,
    exteriorRemodel: projectTypes.exteriorRemodel,
    roofing: projectTypes.roofing,
    hvac: projectTypes.hvac
  }
}

export const projectTypesInTwoColumns = () => {
  const projectTypes = getProjectTypes()
  return {
    doors: projectTypes.doors,
    roofing: projectTypes.roofing,
    exteriorRemodel: projectTypes.exteriorRemodel,
    hvac: projectTypes.hvac,
    windows: projectTypes.windows,
    interiorRemodel: projectTypes.interiorRemodel
  }
}

export const getProjectTypesInThreeColumns = () => {
  let projectTypesAux = projectTypesInThreeColumns()

  projectTypesAux = merge(projectTypesAux, {
    solar: getProjectTypes().solar
  })

  return Object.entries(projectTypesAux)
}
export const getProjectTypesInTwoColumns = () => {
  const projectTypes = projectTypesInTwoColumns()
  let projectTypesAux = {
    doors: projectTypes.doors,
    roofing: projectTypes.roofing,
    exteriorRemodel: projectTypes.exteriorRemodel
  }

  projectTypesAux = merge(projectTypes, {
    solar: getProjectTypes().solar
  })

  projectTypesAux = merge(projectTypesAux, {
    hvac: projectTypes.hvac,
    windows: projectTypes.windows,
    interiorRemodel: projectTypes.interiorRemodel
  })

  return Object.entries(projectTypesAux)
}
