import { Container } from 'unstated'

interface State {
  messages: Array<string>
}

class ErrorMessageContainer extends Container<State> {
  state = {
    messages: []
  }

  add = (message: string): void => {
    this.setState(prevState => ({
      messages: prevState.messages.concat(message)
    }))
  }

  handleClose = (message: string): void => {
    this.setState(prevState => ({
      messages: prevState.messages.filter(item => item !== message)
    }))
  }
}

export default ErrorMessageContainer
