import { Container } from 'unstated'
import { UploadedFile } from '../types'

interface State {
  uploadedFiles: Array<UploadedFile>
}

export default class FileUploadContainer extends Container<State> {
  state = {
    uploadedFiles: []
  }

  addFile = async (file: UploadedFile): Promise<void> => {
    await this.setState(prevState => ({
      uploadedFiles: prevState.uploadedFiles.concat(file)
    }))
  }

  setUploadedFiles = async (files: Array<UploadedFile>): Promise<void> => {
    await this.setState({
      uploadedFiles: files
    })
  }

  getUploadedFiles = (): Array<UploadedFile> => {
    const { uploadedFiles } = this.state
    return uploadedFiles
  }
}

export const fileUploadContainer = new FileUploadContainer()
