import React from 'react'
import { flow } from 'lodash'
import Button from '@material-ui/core/Button'
import { withSnackbar } from 'notistack'
import CloseIcon from '@material-ui/icons/Close'
import withContainers from 'store/withContainers'
import ErrorMessageContainer from 'components/shared/ErrorMessageContainer'

interface Props {
  closeSnackbar: Function
  enqueueSnackbar: Function
  errorMessageContainer: any
}

const ErrorMessage = (props: Props) => {
  const { errorMessageContainer } = props

  const getAction = (key?: string) => (
    <>
      <Button
        onClick={() => {
          const { closeSnackbar } = props
          closeSnackbar(key)
        }}
      >
        <CloseIcon style={{ color: '#fff' }} />
      </Button>
    </>
  )

  const handleClick = (message, errorMessageContainer) => {
    const { enqueueSnackbar } = props
    enqueueSnackbar(message, {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      dense: true,
      autoHideDuration: 6000,
      action: getAction(),
      onExited: () => {
        errorMessageContainer.handleClose(message)
      }
    })
  }

  return (
    <div>
      {errorMessageContainer.state.messages.map(message => {
        handleClick(message, errorMessageContainer)
        return null
      })}
    </div>
  )
}

export default flow(
  withContainers([ErrorMessageContainer]),
  withSnackbar
)(ErrorMessage)
