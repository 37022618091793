import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Provider } from 'unstated'
import DocumentTitle from 'react-document-title'
import TagManager from 'react-gtm-module'
import { progressContainer } from 'store/ProgressContainer'
import { stagesContainer } from 'store/StagesContainer'
import { DOCUMENT_TITLE } from './properties/properties'
import * as serviceWorker from './serviceWorker'
import './index.css'
import AppWrapper from './AppWrapper'

const tagManagerArgs = {
  gtmId: 'GTM-W95XCR2'
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider inject={[progressContainer, stagesContainer]}>
    <Router>
      <CssBaseline>
        <DocumentTitle title={DOCUMENT_TITLE.appName} />
        <AppWrapper />
      </CssBaseline>
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
