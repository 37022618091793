import PropTypes from 'prop-types'
import merge from 'lodash/merge'

export default theme => {
  const paper = {
    container: {
      flex: '1 0 0%',
      padding: theme.spacing(2.5),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: theme.spacing(4)
      }
    },
    root: {
      position: 'relative',
      margin: theme.spacing(1.5),
      borderRadius: 6,
      maxWidth: theme.breakpoints.values.md - theme.spacing(2),
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        marginRight: 'auto',
        marginLeft: 'auto'
      }
    }
  }

  const subheading = {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.fontSize,
    fontWeight: '900',
    paddingTop: theme.spacing(8),
    height: 115
  }

  return {
    root: {
      overflow: 'auto',
      backgroundColor: 'transparent',
      height: '100%'
    },
    paper: merge({}, paper.container, paper.root),
    paperContainer: paper.container,
    paperRoot: paper.root,
    subheadingLight: {
      ...subheading,
      color: theme.palette.common.white
    },
    subheadingDark: {
      ...subheading,
      color: theme.palette.common.black
    },
    headerReplacement: {
      height: 55
    },
    row: {
      padding: theme.spacing(2)
    },
    circularProgress: {
      position: 'absolute',
      top: '30%',
      left: 0,
      right: 0,
      margin: 'auto',
      color: theme.palette.grey['400']
    },
    circularProgressBlock: {
      display: 'block',
      margin: `${theme.spacing(2)}px auto`,
      color: theme.palette.grey['400']
    },
    selectPlaceholder: {
      color: 'rgba(0,0,0,0.38)'
    },
    sensitiveInfoInputWebkit: {
      'text-security': 'disc',
      '-webkit-text-security': 'disc',
      '-moz-text-security': 'disc'
    },
    sensitiveInfoInput: {
      'font-family': 'text-security-disc',
      '&::placeholder': {
        'font-family': theme.typography.fontFamily
      }
    },
    embedResponsive: {
      position: 'relative',
      paddingTop: '56.25%', // 16:9, for more: https://github.com/twbs/bootstrap/blob/v4.1.3/scss/utilities/_embed.scss
      height: 0,
      marginBottom: theme.spacing(2)
    },
    embedResponsiveItem: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0
    },
    pagination: {
      paddingBottom: theme.spacing(3)
    }
  }
}

export const coreScreenStylesPropsShape = PropTypes.shape({
  root: PropTypes.string.isRequired,
  paper: PropTypes.string.isRequired,
  subheadingLight: PropTypes.string.isRequired,
  subheadingDark: PropTypes.string.isRequired,
  headerReplacement: PropTypes.string.isRequired
})
