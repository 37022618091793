export default {
  intro: 'start',
  applicant: 'applicant',
  business: 'business',
  businessForm: 'business-form',
  businessNameDBAEIN: 'business-name-dba-ein',
  businessServicesSales: 'business-services-sales',
  businessStateOfOperation: 'business-state-of-operation',
  businessStateOfOperationPrimaryState:
    'business-state-of-operation-primary-state',
  businessStateOfOperationAddtionalStates:
    'business-state-of-operation-additional-states',
  businessBankAccount: 'business-bank-account',
  businessWebsiteEIN: 'business-website-ein',
  principal: 'principal',
  principalForm: 'principal-form',
  principalAddress: 'principal-address',
  principalName: 'principal-name',
  principalSSNDoB: 'principal-ssn-dob',
  review: 'review',
  status: 'status',
  businessAddressWebsite: 'business-address-website',
  contactMe: 'contact-me'
}
