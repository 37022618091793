import get from 'lodash/get'
import applyStatuses from 'constants/enums/applyStatuses'
import stageSlugs from 'constants/enums/stageSlugs'
import validations from 'properties/validations'
import basePaths from 'constants/enums/basePaths'
import { Applicant, Slug, Stage, ApplicantId, Section } from 'types'

type Fields = Array<string>

const areFieldsValid = (applicant: Applicant, fields: Fields = []): boolean =>
  fields.every(field => {
    const value: string = get(applicant, field)
    const validation = get(validations, field)

    return validation.isValidSync(value)
  })

export const blockCardUntilSave = (slug: Slug): boolean =>
  [
    stageSlugs.applicant,
    stageSlugs.businessForm,
    stageSlugs.businessWebsiteEIN,
    stageSlugs.principalForm
  ].includes(slug)

export const isValidSection = (
  stages: Array<Stage>,
  slug: Slug | null
): boolean => {
  if (!slug) return false
  return stages.map(stage => stage.slug).includes(slug)
}
export const mostAdvancedCard = (
  stages: Array<Stage>,
  applicant: Applicant
): Slug => {
  const firstUncompletedStage = stages.find(
    ({ fields }) => !areFieldsValid(applicant, fields)
  )

  if (!firstUncompletedStage) return stageSlugs.review

  return firstUncompletedStage.slug
}

export const slugToPath = (
  slug: Slug,
  applicantId?: ApplicantId | null
): string => (applicantId ? `/apply/${applicantId}/${slug}` : `/apply/${slug}`)

export const nextSlug = (
  stages: Array<Stage>,
  slug: Slug,
  slugToSkip: Slug | null = null
): Slug => {
  if (!slug) {
    return stageSlugs.applicant
  }

  const slugs: Array<Slug> = stages.map(stage => stage.slug)
  const currentIndex: number = slugs.indexOf(slug)
  let index: number = Math.min(currentIndex + 1, slugs.length - 1)

  if (slugs[index] === slugToSkip) {
    index += 1
  }

  return slugs[index]
}

export const nextCard = (
  stages: Array<Stage>,
  slug: Slug,
  applicantId: ApplicantId | null,
  slugToSkip: Slug | null = null
): string => {
  const next: Slug = nextSlug(stages, slug, slugToSkip)

  return slugToPath(next, applicantId)
}

export const prevSlug = (stages: Array<Stage>, slug: Slug): Slug => {
  const slugs: Array<Slug> = stages.map(stage => stage.slug)
  const currentIndex: number = Math.max(slugs.indexOf(slug), 1)
  return slugs[currentIndex - 1]
}

export const prevCard = (
  stages: Array<Stage>,
  slug: Slug,
  applicantId: ApplicantId | null
): string => {
  // when zero ldFlag is enabled this avoids that when pressing the back button from business
  // intro page redirects to the contact-me page

  if (slug === stageSlugs.business) {
    return applicantId
      ? `${basePaths.apply}/${applicantId}/${stageSlugs.applicant}`
      : `${basePaths.apply}/${stageSlugs.applicant}`
  }

  if (slug === stageSlugs.intro) {
    return '/'
  }

  const prev: Slug = prevSlug(stages, slug)

  return slugToPath(prev, applicantId)
}

const buildRedirectPath = (
  currentSlug: Slug | null,
  redirectTo: string,
  applicantId: ApplicantId | null = null
): string | null => {
  if (currentSlug === redirectTo) return null

  return slugToPath(redirectTo, applicantId)
}

export const pathToRedirect = (
  applicant: Applicant,
  applicantId: ApplicantId | null = null,
  section: Section,
  stages: Array<Stage>
): string | null => {
  // Redirects if the user wants to go to an advanced card where a non-applicant shouldn't access to
  if (!applicantId) {
    const shouldRedirectNewUser: boolean = ![
      stageSlugs.intro,
      stageSlugs.applicant,
      stageSlugs.contactMe
    ].includes(section)

    if (shouldRedirectNewUser)
      return buildRedirectPath(section, stageSlugs.intro)

    return null
  }

  if (isValidSection(stages, section)) {
    const { status } = applicant
    const isInProgress: boolean =
      !status || [applyStatuses.created].includes(status)

    // Redirects to status if the application was submitted
    if (!isInProgress) {
      return buildRedirectPath(section, stageSlugs.status, applicantId)
    }
    // Redirects if the application wasn't submitted and the user wants to go to the status screen
    if (section === stageSlugs.status) {
      const slug: Slug = mostAdvancedCard(stages, applicant)

      return buildRedirectPath(section, slug, applicantId)
    }

    return null
  }

  // Evergreen link, invalid stage name, redirects to the most advanced card
  const slug: Slug = mostAdvancedCard(stages, applicant)

  return buildRedirectPath(section, slug, applicantId)
}
