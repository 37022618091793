import keys from 'lodash/keys'
import get from 'lodash/get'
import validations from 'properties/validations'
import applyFields from 'constants/enums/applyFields'
import { StateOfOperation, Applicant } from 'types'

const stateOfOperationsObjectToArray = (
  stateOfOperationsObj: StateOfOperation
): Array<StateOfOperation> => {
  const stateKeys = Object.keys(stateOfOperationsObj)

  const stateOfOperationsArray = stateKeys.reduce(
    (result: Array<StateOfOperation>, stateAbbreviation) => {
      const obj: StateOfOperation = {}
      obj[stateAbbreviation] = stateOfOperationsObj[stateAbbreviation]

      result.push(obj)
      return result
    },
    []
  )
  return stateOfOperationsArray
}

const licenseNeeded = (
  stateOfOperation: StateOfOperation | Array<StateOfOperation>
): boolean => {
  const stateAbbreviation: string = keys(stateOfOperation)[0]
  const stateOfOperationObj = stateOfOperation[stateAbbreviation]
  const licenses = stateOfOperationObj.licenses

  if (stateOfOperationObj && licenses) {
    return (
      (!stateOfOperationObj.hasNoLicenses && !licenses) ||
      licenses.length === 0 ||
      ((!stateOfOperationObj.hasNoLicenses &&
        licenses.length === 1 &&
        licenses[0] === undefined) ||
        licenses[0] === '')
    )
  }

  return true
}

const validateStateOfOperation = (applicant: Applicant): boolean => {
  const statesOfOperation: StateOfOperation = get(
    applicant,
    applyFields.businessStateOfOperation
  )
  const validation: any = get(validations, applyFields.businessStateOfOperation)

  if (statesOfOperation) {
    return stateOfOperationsObjectToArray(statesOfOperation).every(state => {
      const stateCopy: any = { ...state }

      if (
        !stateCopy[Object.keys(stateCopy)[0]].hasNoLicenses &&
        stateCopy[Object.keys(stateCopy)[0]].licenses.length === 0
      ) {
        stateCopy[Object.keys(stateCopy)[0]].licenses = [undefined]
      }

      const isValid: boolean =
        validation.isValidSync(stateCopy) &&
        get(
          validations,
          applyFields.businessPrimaryStateOfOperation
        ).isValidSync(Object.keys(stateCopy)[0])

      return isValid
    })
  }

  return false
}

export default {
  stateOfOperationsObjectToArray,
  licenseNeeded,
  validateStateOfOperation
}
