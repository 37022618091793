import { get, set, invert, split, has } from 'lodash'
import getSfToMagnesSchema from 'constants/enums/sfToMagnesSchema'
import getSfSchema from 'constants/enums/sfSchema'
import applyFields from 'constants/enums/applyFields'

const setField = (
  key: string,
  sfKey: string,
  data: any,
  schema: Object
): void => {
  if (!has(data, sfKey)) return
  set(schema, key, get(data, sfKey))
}

const transformSalesforceToMagnesAPI = (sfData: any): any => {
  const sfToMagnesSchema = getSfToMagnesSchema()
  const magnesApiSchema = {}

  setField(
    'id',
    `applicant.${sfToMagnesSchema.APPLICANT_ID}`,
    sfData,
    magnesApiSchema
  )
  setField('id', `applicant.${sfToMagnesSchema.ID}`, sfData, magnesApiSchema)

  setField(
    'businessName',
    `applicant.${sfToMagnesSchema.APPLICANT_COMPANY}`,
    sfData,
    magnesApiSchema
  )

  setField(
    'business.name',
    `applicant.${sfToMagnesSchema.APPLICANT_COMPANY}`,
    sfData,
    magnesApiSchema
  )

  setField(
    'firstName',
    `applicant.${sfToMagnesSchema.APPLICANT_FIRSTNAME}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'lastName',
    `applicant.${sfToMagnesSchema.APPLICANT_LASTNAME}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'email',
    `applicant.${sfToMagnesSchema.APPLICANT_EMAIL}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'phone',
    `applicant.${sfToMagnesSchema.APPLICANT_PHONE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'tosDoc',
    `applicant.${sfToMagnesSchema.TOS_DOC}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'p2xUrl',
    `applicant.${sfToMagnesSchema.APPLICANT_P2XURL}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'agreementVersion',
    // @ts-ignore
    `applicant.${sfToMagnesSchema.AGREEMENT_VERSION}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'agreementAcceptedDate',
    `applicant.${sfToMagnesSchema.APPLICANT_AGREEMENT_DATE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'loanType',
    // @ts-ignore
    `applicant.${sfToMagnesSchema.APPLICANT_LOAN_TYPE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'status',
    `applicant.${sfToMagnesSchema.APPLICANT_STATUS}`,
    sfData,
    magnesApiSchema
  )

  setField('id', sfToMagnesSchema.ID, sfData, magnesApiSchema)
  setField(
    'principal.sameAsApplicant',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_SAME_AS_OWNER}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'principal.hasNoSSN',
    `applicant.${sfToMagnesSchema.APPLICANT_HAS_NO_SSN}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'principal.ssn',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_SSN}`,
    sfData,
    magnesApiSchema
  )

  if (has(sfData, 'applicant.mg_Contractor_Licenses__r.records')) {
    const { records } = sfData.applicant.mg_Contractor_Licenses__r
    const stateOfOperation = records.map(record => {
      const stateOfOperationRow: any = {}

      stateOfOperationRow.state = get(
        record,
        `${sfToMagnesSchema.APPLICANT_MG_CONTRACTOR_LICENSES_RECORDS_STATE}`
      )

      stateOfOperationRow.license = get(
        record,
        `${
          sfToMagnesSchema.APPLICANT_MG_CONTRACTOR_LICENSES_RECORDS_LICENSE_NUMBER
        }`
      )

      stateOfOperationRow.isPrimary = get(
        record,
        `${
          sfToMagnesSchema.APPLICANT_MG_CONTRACTOR_LICENSES_RECORDS_IS_PRIMARY
        }`
      )

      stateOfOperationRow.hasNoLicenses = get(
        record,
        `${sfToMagnesSchema.APPLICANT_MG_CONTRACTOR_HAS_NO_LICENSES}`
      )

      return stateOfOperationRow
    })

    set(magnesApiSchema, 'business.stateOfOperation', stateOfOperation)
  }

  setField(
    'business.hasNoEIN',
    `applicant.${sfToMagnesSchema.APPLICANT_HAS_NO_EIN}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'business.taxpayerIdentificationNumber',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_FEDERAL_TAX_ID}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'business.annualSales',
    `applicant.${sfToMagnesSchema.APPLICANT_PAYFIN_PV_REVENUE}`,
    sfData,
    magnesApiSchema
  )

  setField(
    applyFields.businessBankAccountRoutingNumber,
    `applicant.${sfToMagnesSchema.APPLICANT_ROUTING_NUMBER}`,
    sfData,
    magnesApiSchema
  )

  setField(
    applyFields.businessBankAccountAccountNumber,
    `applicant.${sfToMagnesSchema.APPLICANT_ACCOUNT_ACCOUNT_NUMBER}`,
    sfData,
    magnesApiSchema
  )

  setField(
    applyFields.businessBankAccountAccountType,
    `applicant.${sfToMagnesSchema.APPLICANT_ACCOUNT_TYPE}`,
    sfData,
    magnesApiSchema
  )

  setField(
    applyFields.businessHasNoBankAccount,
    `applicant.${sfToMagnesSchema.APPLICANT_HAS_NO_BANK_ACCOUNT}`,
    sfData,
    magnesApiSchema
  )

  setField(
    'business.hasNoWebsiteUrl',
    `applicant.${sfToMagnesSchema.APPLICANT_HAS_NO_WEBSITE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'business.websiteUrl',
    `applicant.${sfToMagnesSchema.APPLICANT_WEBSITE}`,
    sfData,
    magnesApiSchema
  )

  if (
    has(sfData, `applicant.${sfToMagnesSchema.APPLICANT_HOME_IMPROVEMENTS}`)
  ) {
    let services: Array<string> | null = null
    if (
      get(sfData, `applicant.${sfToMagnesSchema.APPLICANT_HOME_IMPROVEMENTS}`)
        .length > 0
    ) {
      services = split(
        get(
          sfData,
          `applicant.${sfToMagnesSchema.APPLICANT_HOME_IMPROVEMENTS}`
        ),
        ';'
      )
    } else services = []

    set(magnesApiSchema, applyFields.services, services)
  }

  setField(
    'business.referralCode',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_REFERRAL_CODE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'business.address_street1',
    `applicant.${sfToMagnesSchema.APPLICANT_STREET}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'business.address_city',
    `applicant.${sfToMagnesSchema.APPLICANT_CITY}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'business.address_stateAbbreviation',
    `applicant.${sfToMagnesSchema.APPLICANT_STATE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'business.address_zipCode',
    `applicant.${sfToMagnesSchema.APPLICANT_POSTAL_CODE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'principal.firstName',
    `applicant.${sfToMagnesSchema.FIRSTNAME}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'principal.lastName',
    `applicant.${sfToMagnesSchema.LASTNAME}`,
    sfData,
    magnesApiSchema
  )

  setField(
    'principal.email',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_PRINCIPAL_EMAIL}`,
    sfData,
    magnesApiSchema
  )

  setField(
    'principal.address_street1',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_PRINCIPAL_STREET}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'principal.address_city',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_PRINCIPAL_CITY}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'principal.address_stateAbbreviation',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_PRINCIPAL_STATE_CODE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'principal.address_zipCode',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_PRINCIPAL_POSTAL_CODE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'principal.phone',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_PRINCIPAL_PHONE}`,
    sfData,
    magnesApiSchema
  )

  setField(
    'business.websiteUrl',
    `applicant.${sfToMagnesSchema.APPLICANT_WEBSITE}`,
    sfData,
    magnesApiSchema
  )

  if (has(sfData, sfToMagnesSchema.HIDDEN_FIELDS)) {
    const fields = get(sfData, sfToMagnesSchema.HIDDEN_FIELDS)

    const inverted = invert(getSfSchema())
    let hiddenFields = {}
    if (fields !== null) {
      hiddenFields = fields.map(value => inverted[`applicant.${value}`])
    }

    set(magnesApiSchema, 'hiddenFields', hiddenFields)
  }

  setField(
    'principal.dateOfBirth',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_DATE_OF_BIRTH}`,
    sfData,
    magnesApiSchema
  )
  setField(
    'dateSubmitted',
    `applicant.${sfToMagnesSchema.APPLICANT_MG_SUBMITTED}`,
    sfData,
    magnesApiSchema
  )

  if (has(sfData, `applicant.${sfToMagnesSchema.APPLICANT_UPLOADED_FILES}`)) {
    const files = get(
      sfData,
      `applicant.${sfToMagnesSchema.APPLICANT_UPLOADED_FILES}`
    )

    let uploadedFiles
    if (files.records.length > 0) {
      uploadedFiles = files.records.map(fileItem => ({
        name: get(fileItem, `${sfToMagnesSchema.APPLICANT_UPLOADED_FILE_NAME}`)
      }))
    } else uploadedFiles = []
    set(magnesApiSchema, 'business.uploadedFiles', uploadedFiles)
  }

  setField(
    applyFields.address_street1,
    `applicant.${sfToMagnesSchema.APPLICANT_STREET}`,
    sfData,
    magnesApiSchema
  )
  setField(
    applyFields.address_city,
    `applicant.${sfToMagnesSchema.APPLICANT_CITY}`,
    sfData,
    magnesApiSchema
  )
  setField(
    applyFields.address_stateAbbreviation,
    `applicant.${sfToMagnesSchema.APPLICANT_STATE}`,
    sfData,
    magnesApiSchema
  )
  setField(
    applyFields.address_zipCode,
    `applicant.${sfToMagnesSchema.APPLICANT_POSTAL_CODE}`,
    sfData,
    magnesApiSchema
  )

  return magnesApiSchema
}

export default transformSalesforceToMagnesAPI
