import React from 'react'

interface Props {
  classes: any
  children: any
  src: any;
  style: any;
}

interface State {
  backgroundLoaded: boolean
}

class LazyLoadBackgroundImage extends React.Component<Props, State> {
  static defaultProps = {
    classes: {},
    style: {}
  }

  state = {
    backgroundLoaded: false
  }

  constructor(props) {
    super(props)

    if (window.document.readyState === 'complete') {
      this.loadImage()
    } else {
      window.addEventListener('load', this.loadImage)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.loadImage)
  }

  loadImage = () => {
    const { src } = this.props
    const image = new Image()

    image.onload = () => {
      this.setState({
        backgroundLoaded: true
      })
    }

    image.src = src
  }

  render() {
    const { classes, children, src, style } = this.props
    const styles: any = {
      backgroundImage: `url("${src}")`,
      backgroundPositionX: 'center',
      backgroundPositionY: 'top',
      ...style
    }

    return (
      <div
        data-testid="lazy-load-background-image"
        className={classes.container}
        style={styles}
      >
        {children}
      </div>
    )
  }
}

export default LazyLoadBackgroundImage
