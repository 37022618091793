import { get, isEmpty } from 'lodash'
import { Container } from 'unstated'
import applyFields from 'constants/enums/applyFields'
import validations, { createFieldContext } from 'properties/validations'
import applyStatuses from 'constants/enums/applyStatuses'
import statesOfOperationHelper from 'utils/statesOfOperationHelper'
import { stagesContainer } from 'store/StagesContainer'
import { Applicant, Flags, Stage } from 'types'

interface Progress {
  lastStep: number
  percentage: number
  status: string | null
  step: number
}

interface State {
  loading: boolean
  previousStatus: string | null
  status: string | null
}

export default class ProgressContainer extends Container<State> {
  state = {
    loading: false,
    previousStatus: null,
    status: null
  }

  calculatePercentageCompleted = (
    savedApplicant: Applicant,
    applicant: Applicant
  ): number => {
    if (savedApplicant.status === applyStatuses.approved) return 100

    const hiddenFields: Array<string> = stagesContainer.getHiddenFields()
    const keys: any = Object.values(applyFields).filter(
      (key: string) =>
        !hiddenFields.includes(key) && key !== applyFields.applicantBusinessName
    )
    let nonRequiredValidFields: number = 0
    let validFields: number = 0
    let validStatesOfOperation: boolean = false

    keys.forEach((key: string) => {
      const context: any = createFieldContext(key, savedApplicant)
      const value: string = get(savedApplicant, key)

      const validation: any = get(validations, key)
      const isValid: boolean = validation.isValidSync(value, { context })

      if (isValid) {
        validFields += 1

        if (isEmpty(value)) {
          nonRequiredValidFields += 1
        }
      }

      if (key === applyFields.businessPrimaryStateOfOperation) {
        validStatesOfOperation = statesOfOperationHelper.validateStateOfOperation(
          applicant
        )
      }
    })

    if (!validStatesOfOperation) {
      validFields -= 1
    }

    return (
      ((validFields - nonRequiredValidFields) * 100) /
      (keys.length - nonRequiredValidFields)
    )
  }

  isLoading = (): boolean => this.state.loading

  getApplyProgress = (
    savedApplicant: Applicant,
    slug: string,
    applicant: Applicant,
    flags: Flags
  ): Progress => {
    const { status } = this.state
    const stages: Array<Stage> = stagesContainer.getStages(flags)
    // @ts-ignore
    const steps: Array<number> = stages.map(card => card.step)
    const lastStep = Math.max(...steps)
    const stage: Stage | undefined = stages.find(card => card.slug === slug)
    const step: number = get(stage, 'step', 0)

    const percentage: number = this.calculatePercentageCompleted(
      savedApplicant,
      applicant
    )

    return {
      lastStep,
      percentage,
      status,
      step
    }
  }

  clearStatus = async (): Promise<void> => {
    if (!this.state.status) return

    await this.setState({
      status: null
    })
  }

  revertStatus = async (): Promise<void> => {
    if (this.state.status === this.state.previousStatus) return

    await this.setState(prevState => ({
      status: prevState.previousStatus,
      previousStatus: null
    }))
  }

  setStatus = async (status: string): Promise<void> => {
    await this.setState(prevState => ({
      previousStatus: prevState.status,
      status
    }))
  }
}

export const progressContainer = new ProgressContainer()
