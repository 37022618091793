import { getProjectTypes } from 'constants/enums/projectTypes'
import { maskPhone } from 'utils/phoneHelper'
import {
  PRINCIPAL_MIN_AGE,
  PRINCIPAL_MAX_AGE,
  SUPPORT_EMAIL
} from './properties'

const layout = {
  poweredByMosaic: 'powered by Mosaic',
  applicationDescription: 'The Onboarding Experince',
  next: 'Next'
}
const start = {
  title1: 'Become a',
  title2: 'Contractor Partner',
  body:
    'Increase sales, close larger deals, and get paid faster by offering home improvement financing through Mosaic.',
  button: 'Apply Now'
}

const apply = {
  section1: {
    intro: 'To start, please give us your contact information.',
    duplicateEmailError: 'An applicant with this email already exists.',
    duplicateEmailLn1: 'Please check your inbox',
    duplicateEmailLn2: email =>
      ` and follow the link to complete your application for ${email}.`,
    email: 'Email address',
    emailHelper:
      'Please provide your work email address. If you don’t finish, don’t worry. We’ll email you a link so you can get back to where you left off.',
    phone: 'Mobile number',
    phoneHelper:
      'We will send you a link that lets you view and resume your application.',
    disclosureTitle: 'Disclosures',
    disclosureNoteTitle: 'Automated Text Message Consent:',
    disclosureNoteDescription:
      'By providing the mobile telephone number above and checking the box “I AGREE” and then proceeding to click the “NEXT” button, you (which includes for the purposes of this consent your business) agree that we (which includes for the purposes of this consent Solar Mosaic LLC ["Mosaic"] and its affiliates, subsidiaries, agents, vendors, and service providers) may send automated text messages to such number in order to send you information relating to your business’s application to become an approved installer or contractor, including hyperlinks to continue your application and to check the status of a submitted application. You understand that, when you receive such text messages, you may incur a charge from the company that provides you with telecommunications services. You agree that we will not be liable to you for any fees, inconvenience, annoyance or loss of privacy in connection with such text messages. To stop receiving text messages from us, you may reply to any text message that we send to you with only the word “STOP.” We will process your request to stop receiving text messages within a reasonable time.',
    firstName: 'First name',
    lastName: 'Last name',
    lastNameHelper:
      'Lorem ipsum dolores abernathy, consectetuer adipiscing elit. Donec odio.',
    agree: 'I agree',
    reCaptchaNoteLn1: 'This site is protected by reCAPTCHA and the Google',
    reCaptchaNotePrivacyPolicy: 'Privacy Policy',
    reCaptchaNoteLn2: 'and',
    reCaptchaNoteTermsOfService: 'Terms of Service',
    reCaptchaNoteLn3: 'apply.',
    sponsorCode: 'Sponsor code (if any)',
    contactMeButton: 'Talk to an Expert',
    contactMeHelperText:
      // eslint-disable-next-line quotes
      "Need more information? You'll hear back from us within 48 hours.",
    continueToApplicationButton: 'Become a Partner Now',
    continueToApplicationHelperText:
      'Mosaic has made it easy for you to offer Powerswitch ZERO. The process is fast and simple.',
    zipCode: 'ZIP code',
    contactMeTitle:
      'Thanks. A Mosaic representative will be contacting you soon.',
    contactMeDescriptionLine1: 'In the meantime, you can choose to ',
    contactMeDescriptionLine2: 'continue with the application now ',
    contactMeDescriptionLine3: 'or follow the link we sent you.',
    zeroHeading:
      // eslint-disable-next-line quotes
      "We're happy to see you've taken the next step. There's just a few more to go!",
    newToFinancing: 'I am new to financing.'
  },
  section2: {
    intro: 'Next, tell us about your business.',
    introWithBusinessName: (businessName: string) =>
      `Next, tell us about ${businessName}.`,
    legalBusinessName: 'Legal business name',
    businessName: 'Business name',
    businessNameHelper:
      'The business name filed with the Secretary of State (SOS).',
    businessDoingBusinessAs: 'DBA (if available)',
    businessInDifferentName: 'I do business in another name',
    businessDoingBusinessAsHelper:
      'Leave blank if you don’t have a Doing Business As (DBA) yet, or skip this an enter it later.',
    securityNote:
      'We take your security seriously. The safety of your data is our top priority. We never share your information with anyone.',
    businessEIN: 'Business EIN',
    businessEINCheckbox: 'There is no company EIN',
    businessEINHelper: 'EIN is your Employer Identification Number.',
    businessAddressStreet: 'Street address (of business)',
    businessAddressCity: 'City',
    businessAddressState: 'State',
    businessAddressZIP: 'ZIP',
    addressNote:
      'If you have several addresses please use your principal place of business.',
    businessWebsite: 'Website URL',
    businessWebsiteHelper:
      'The website URL is the web address your customer would use to find you on the internet.',
    businessWebsiteCheckbox: 'There is no company website',
    businessStateOfOperationState: 'State of operation',
    businessStateOfOperationAdd: 'Add License #',
    businessStateOfOperationRemove: 'Remove',
    businessStateOfOperationModalAdd: 'Add',
    businessStateOfOperationModalCancel: 'Cancel',
    businessStateOfOperationModalTitle: state => `State of ${state}`,
    businessStateOfOperationPrimary: 'Primary State of Business Operation',
    businessStateOfOperationAddionalStates: 'Additional state of operation',
    businessStateOfOperationAddionalStatesImportant: primaryState =>
      `Please enter information for each additional state where your business operates. Leave this section blank if your business only operates in ${primaryState}.`,
    businessAdditionalStateOfOperationAddAnother: 'Add another',
    businessStateOfOperationPrimaryHelper: '',
    businessStateOfOperationNoLicense: ({ name }) =>
      `I don't have a license number / not applicable for ${name}`,
    businessStateOfOperationOnlyState: ({ name }) =>
      `I only do business in ${name}`,
    businessStateOfOperationLicense: ({ name }) =>
      name ? `${name} license number` : '',
    businessAnnualSales: 'Annual revenue',
    businessServices: 'Services offered',
    businessPrimary: 'Primary business',
    businessImportant: 'Important: ',
    businessImportantText:
      'Providing the exact and correct Legal Business Name and Business EIN is critical for a speedy approval. ',
    businessNotSureAboutYours: 'Not sure about yours?',
    bankAccountIntro:
      'Where should we send the money? Adding your business bank account now will help us process your application more quickly.',
    bankAccountRoutingNumber: 'Routing number',
    bankAccountAccountNumber: 'Account number',
    bankAccountAccountType: 'Account type',
    bankAccountNoAccount: 'I don’t have my bank account information available',
    uploadDocuments: {
      title: 'Upload one of the following documents:',
      dragFiles: 'Drag & drop files here or',
      chooseFiles: 'choose files',
      chooseFilesMobile: 'Choose a file to upload',
      uploadedFiles: 'Uploaded files:',
      error: {
        fileUploadError: fileName =>
          `Unable to upload "${fileName}". Please try again.`
      },
      LLC: {
        title: 'LLC: ',
        description: 'Articles of Organization or most recent amendment'
      },
      Inc: {
        title: 'Inc: ',
        description: 'Articles of Incorporation or most recent amendment'
      },
      Partnership: {
        title: 'Partnership: ',
        description: 'Partnership Agreement or most recent amendment'
      },
      SoleProprietorship: {
        title: 'Sole Proprietorship: ',
        description: 'Fictitious Business Name (county of registration)'
      },
      EIN: {
        title: 'EIN: ',
        description: 'Tax ID/EIN registration'
      }
    }
  },
  section3: {
    intro: 'Finally, we’d like some info about the owner of the business.',
    principalFirstName: 'First name (of owner)',
    principalLastName: 'Last name (of owner)',
    principalEmail: 'Email address (of owner)',
    principalPhone: 'Mobile number (of owner)',
    phoneHelper:
      'We will send you a link that lets you view and resume your application.',
    copyActionMessage: 'Link copied to clipboard',
    copyUrl: 'Copy URL',
    ownerNote:
      'The owner is the business principal. If your business is owned by an individual, that individual would be the principal. If your business is an S-Corp, the principal is the majority owner of that business.',
    shareNoteTitle: 'Not the owner?',
    shareNoteDescription:
      'Share this link for them to complete the rest of this application.',
    principalAddressStreet: 'Street address (of owner)',
    principalAddressCity: 'City',
    principalAddressState: 'State',
    principalAddressZipCode: 'ZIP',
    principalHasNoSSN: 'I don’t have the owner SSN available',
    principalSSN: 'Social Security Number (of owner)',
    principalSSNHelper:
      'Providing an SSN now will help us evaluate your application faster. If left blank, we may reach out to you later.',
    principalDateOfBirth: 'Date of birth (of owner)',
    principalDateOfBirthMaxDate: `Must be ${PRINCIPAL_MIN_AGE} or older.`,
    principalDateOfBirthMinDate: `Must be younger than ${PRINCIPAL_MAX_AGE}.`,
    principalSameAsApplicant: (firstName, lastName, email, phone) =>
      `Owner information is the same as "${firstName} ${lastName}, ${email}, ${maskPhone(
        phone
      )}."`,
    softNote:
      'This application review will result in a soft credit pull. This application review inquiry does not affect your credit score and thus is only visible to you on your credit report.',
    authorizationNote:
      'Authorization to pull credit  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation.'
  },
  review: {
    agree: 'I agree',
    authorizationNoteTitle: 'Authorization to Obtain Consumer Reports:',
    authorizationNote1:
      'By checking the “I AGREE” box and clicking the “SUBMIT APPLICATION” button below, you authorize Solar Mosaic LLC, including its successors, assignees, agents, representatives, subsidiaries, or affiliates (“Mosaic,” “we,” “us,” or “our”), to obtain your consumer report from one or more consumer reporting agencies (i.e. Experian, Equifax, TransUnion) when you submit an application on behalf of your business to become an approved Mosaic installer or contractor and, if you are approved, periodically throughout the time you are an approved Mosaic installer or contractor.',
    authorizationNote2:
      'You agree that Mosaic may use the information obtained from your consumer report: (a) to determine whether your business is eligible to become an approved Mosaic installer or contractor and, if your business is approved, whether it continues to be eligible for approval; (b) for our internal modeling and analysis; (c) to verify the information you submitted (including to protect against any fraudulent activity); or (d) among other lawful purposes.',
    authorizationNote3:
      'You understand that the credit inquiry performed as part of your business’s application to become an approved Mosaic installer or contractor may result in a “soft” or “hard” inquiry in your credit report. You further understand that a “soft” or “hard” inquiry may impact your FICO score.',
    emptyField: 'none',
    fixButton: 'Fix',
    stateOfOperationValue: ({ state, license }) => {
      if (license) return `Operates in ${state} License # ${license}`

      return `Operates in ${state}`
    },
    uploadedFilesValue: ({ name }) => `Uploaded file: ${name}`,
    informationWarning:
      'Please make sure all information is accurate as any errors will significantly delay your approval.',
    submitApplicationButton: 'Submit Application',
    title: 'Review & submit',
    sections: {
      applicant: {
        title: 'About the applicant',
        fields: {
          email: 'Email',
          fullName: 'Full name',
          phone: 'Phone',
          zipCode: 'Zip code',
          businessAddress: 'Business Address',
          sponsorCode: 'Sponsor code'
        }
      },
      business: {
        title: 'About the business',
        fields: {
          name: 'Legal business name',
          dba: 'DBA',
          address: 'Address',
          annualSales: 'Annual Revenue',
          ein: 'Business EIN',
          services: 'Services offered',
          primaryBusiness: 'Primary business',
          stateOfOperation: 'State of operation',
          primaryStateOfOperation: 'Primary State of Operation',
          additionalStates: 'Additional States of Operation:',
          showLicenseNumbers: 'show license numbers ...',
          showMoreLicenseNumbers: number => `show ${number} more`,
          hideLicenseNumbers: 'hide',
          licenseNumber: 'License Number',
          licenseNeeded: 'License Needed',
          websiteUrl: 'Website URL',
          uploadedFiles: 'Uploaded Files',
          routingNumber: 'Routing number',
          accountNumber: 'Account number',
          accountType: 'Account type',
          hasNoBankAccount: 'Bank Account'
        }
      },
      principal: {
        title: 'About the owner',
        fields: {
          address: 'Address',
          fullName: 'Full name',
          dateOfBirth: 'Date of birth',
          ssn: 'SSN',
          email: 'Email',
          phone: 'Phone'
        }
      }
    }
  },
  status: {
    title: 'Status',
    thankYouTos: {
      title: 'Check your inbox',
      body:
        'Thank you for applying to become a Mosaic Contractor Partner. Please accept our Participation Agreement and we will begin reviewing your application.',
      important: partnerEmail =>
        `In order to proceed, please check your email (${partnerEmail}) and accept our Participation Agreement.`,
      email: SUPPORT_EMAIL
    },
    thankYou: {
      title: 'Thank you',
      body:
        'Thank you for applying to become a Mosaic Contractor Partner. We’re reviewing your application and will provide an update shortly.',
      important: 'Check your inbox or check back here for details.',
      body2:
        'If you have any questions please contact our application support team at ',
      email: SUPPORT_EMAIL
    },
    terms: {
      title: 'Terms & Conditions',
      subtitle: 'CALLING/TEXT MESSAGING & CALL RECORDING CONSENT: ',
      body: [
        'By checking the box “I AGREE” above, you (which includes for purposes of this consent your business) agree to the terms of this Calling/Text Messaging and Call Recording Consent. You agree that Solar Mosaic LLC and its assigns, successors, agents, representative, vendors, affiliates, subsidiaries, or servicers (collectively, “we,” “us,” “our,” or “Mosaic”) may contact you at the number(s) you have provided on or with your application to become an approved Mosaic installer or contractor or any other number you provide to us, including through automated telephone dialing and text messaging systems to deliver messages or with the use of prerecorded and/or artificial voice. Among other reasons, we may contact you to market products or services to you, with information or questions about your installer or contractor account relationship with Mosaic, and other important communications relating to your installer or contractor account(s). Telephone messages may be played by a machine automatically when the telephone is answered, whether answered by you or someone else. These messages may also be recorded by your answering machine or voicemail inbox.',
        'You also understand and agree that, subject to applicable law, we may monitor and/or record any of your phone conversations with any of our representatives for training, quality control, evidentiary, and any other purposes. However, we are not under any obligation to monitor, record, retain, or reproduce such recordings, unless required by applicable law.',
        'You understand that, when you receive such calls or text messages you may incur a charge from the company that provides you with telecommunications services. You agree that we will not be liable to you for any fees, inconvenience, annoyance or loss of privacy in connection with such calls or text messages. You understand that anyone with access to your telephone or email account may listen to or read the messages, notwithstanding our efforts to communicate only with you. If a telephone number(s) you have provided to us changes, or if you cease to be the owner, subscriber or primary user such telephone number(s), you agree to immediately give us notice of such facts so that we may update our records. To request additional information, text HELP to any text message you receive or contact us by telephone at ',
        '. To stop receiving text messages from us, you may reply to any text message that we send to you or initiate text message to us at ',
        ' with only the word “STOP.” We will process your request to stop receiving text messages within a reasonable time. To stop receiving autodialed and/or prerecorded/artificial voice calls from us, please contact us at ',
        ' or ',
        '.'
      ],
      button: 'I Agree'
    },
    underReview: {
      title: 'Your application review is in process',
      body:
        'Thank you for applying to become a Mosaic Contractor Partner. We’re reviewing your application and will provide an update shortly.',
      important: 'Check your inbox or check back here for details.',
      body2:
        'If you have any questions please contact our application support team at ',
      email: SUPPORT_EMAIL,
      body3: 'using the same email you provided in your application.'
    },
    approved: {
      title: 'Congratulations! You’ve been approved',
      body:
        'Please take a moment to review your partner agreement. If you have any questions, please contact your Mosaic account manager. We look forward to working with you.',
      button: 'View Agreement'
    },
    denied: {
      title: 'Please check your inbox',
      body:
        'We’ve updated your application status. Please check your email inbox.'
    },
    congratulations: {
      title: 'Welcome!',
      body:
        'You are now a Mosaic Contractor Partner. Your dedicated Mosaic account manager will be in touch with you shortly.',
      important: 'Expect to hear from us within 1-3 days.',
      body2:
        // eslint-disable-next-line quotes
        "If you don't hear from us for some reason, please give us a call at:"
    },
    termsOfService: {
      title: 'Participation Agreement',
      acceptButton: 'I ACCEPT',
      acceptLabel: (firstName, lastName, company) =>
        `I, ${firstName} ${lastName}, represent and warrant that I have the power and authority to sign on behalf of ${company}, and I have read and agree to the terms of the Participation Agreement.`,
      emailSent:
        'We will email you a copy of the Participation Agreement for your records.',
      error:
        'An error occurred while trying to accept the Participation Agreement',
      unableToDisplay: 'Unable to display Participation Agreement.',
      alreadyAccepted: 'Already accepted.'
    }
  },
  error: {
    code404: {
      title: 'Page not found',
      body:
        'Sorry, the page you were looking for does not exist or is no longer available.',
      code: 'Error code 404',
      button: 'Go to Start'
    },
    maintenanceMode: {
      title: 'Planned Maintenance',
      // eslint-disable-next-line quotes
      body: "We're in the process of updating our partner application."
    }
  }
}

const loadingP2X = {
  body1: 'Preparing your Mosaic Portal.',
  body2: 'It just takes a few seconds.',
  tosError: 'Participation Agreement already accepted'
}

const progressIndicator = {
  complete: 'Ready to submit',
  incomplete: 'Incomplete',
  intro: '',
  saving: 'Saving...',
  submitted: 'Complete',
  emailVerified: 'Email Verified'
}

const services = () => {
  const projectTypes = getProjectTypes()

  return {
    [projectTypes.hvac]: 'HVAC',
    [projectTypes.roofing]: 'Roofing',
    [projectTypes.doors]: 'Doors',
    [projectTypes.windows]: 'Windows',
    [projectTypes.solar]: 'Solar',
    [projectTypes.interiorRemodel]: 'Interior Remodel',
    [projectTypes.exteriorRemodel]: 'Exterior Remodel',
    [projectTypes.custom]: 'Custom'
  }
}

const desktopStages = {
  welcome: { name: 'Welcome' },
  applicant: { name: 'Applicant', title: 'Contact Information' },
  businessIntro: { name: 'Business Intro' },
  businessForm: { name: 'Business Form', title: 'Business Information' },
  principalIntro: { name: 'Principal Intro' },
  principalForm: { name: 'Principal Form', title: 'Owner Information' },
  reviewAndSubmit: { name: 'Review & Submit' },
  status: { name: 'Status', title: 'Current Status' },
  contactMe: { name: 'Contact Me' },
  termsOfService: { name: 'Terms of Service' }
}

const phoneStages = {
  welcome: { name: 'Welcome' },
  applicant: { name: 'Applicant', title: 'Contact Information' },
  businessIntro: { name: 'Business Intro' },
  businessNameDbaEin: { name: 'Business Name, DBA & EIN' },
  businessStateOfOperationPrimaryState: {
    name: 'Business State of Operation Primary State'
  },
  businessAdditionaStatesOfOperation: { name: 'Business Addtional States' },
  businessAddresAndWebsiteUrl: { name: 'Business Address & Website URL' },
  businessServicesAndSales: { name: 'Business Services & Sales' },
  businessBankAccount: { name: 'Business Bank Account' },
  principalIntro: { name: 'Principal Intro' },
  principalName: { name: 'Principal Name', title: 'Owner Information' },
  principalAddress: { name: 'Principal Address', title: 'Owner Information' },
  principalSsnAndDateOfBirth: {
    name: 'Principal SSN, Date of Birth',
    title: 'Owner Information'
  },
  reviewAndSubmit: { name: 'Review & Submit' },
  status: { name: 'Status', title: 'Current Status' },
  termsOfService: { name: 'Terms of Service' }
}

const validationErrors = {
  required: 'Required',
  bankAccount: {
    field: 'Must be exactly 9 digits',
    min: 'Should be longer than 9 digits',
    max: 'Should not be longer than 16 digits',
    blank:
      'Account type should not be present unless you are adding routing & account numbers'
  },
  state: {
    exact: 'Must be exactly two letters'
  },
  addressCity: {
    min: 'Should be longer than 2 characters',
    onlyLetters: 'Only accept letters'
  },
  dateOfBirth: {
    invalid: 'Invalid date'
  },
  ein: {
    invalid: 'Invalid Business EIN format'
  },
  email: {
    invalid: 'Invalid email'
  },
  phone: {
    invalid: 'Phone should be a number',
    max: 'Maximum 10-digit Phone Number',
    min: 'Minimum 10-digit Phone Number'
  },
  referralCode: {
    max: 'Maximum 15 characters'
  },
  services: {
    invalid: 'Please select at least one service'
  },
  primaryBusiness: {
    required: 'Please select a Primary Business'
  },
  ssn: {
    invalid: 'SSN should be a number',
    max: 'Maximum 9-digit SSN',
    min: 'Minimum 9-digit SSN'
  },
  stateAbbreviation: {
    invalid: 'Two-letter state abbreviation'
  },
  website: {
    invalid: 'Invalid URL'
  },
  zip: {
    invalid: 'Zip code should be a number',
    max: 'Maximum 5-digit zip code',
    min: 'Minimum 5-digit zip code'
  },
  businessName: {
    max: 'Too Long!'
  }
}

export default {
  apply,
  layout,
  progressIndicator,
  services,
  start,
  validationErrors,
  desktopStages,
  phoneStages,
  loadingP2X
}
