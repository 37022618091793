// @ts-nocheck
import pick from 'lodash/pick'
import * as Yup from 'yup'
import get from 'lodash/get'
import { parse, isValid, toDate } from 'date-fns'
import applyFields from 'constants/enums/applyFields'
import states from 'constants/enums/states'
import l10n from 'properties/translations'
import { PRINCIPAL_MIN_AGE, PRINCIPAL_MAX_AGE } from './properties'

const hasFieldSelected = value =>
  value && Object.values(value).some(checked => checked)

export const getDateDiff = age => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - age)
  return date
}

export const ROUTING_NUMBER_LENGTH = 9

const bankFieldValidator = validation =>
  Yup.string().when('hasNoBankAccount', {
    is: hasNoBankAccount => hasNoBankAccount !== true,
    then: validation
  })

const addressValidations = {
  address_street1: Yup.string().required(l10n.validationErrors.required),
  address_city: Yup.string()
    .min(2, l10n.validationErrors.addressCity.min)
    .matches(/^[a-zA-Z\s]+$/, l10n.validationErrors.addressCity.onlyLetters)
    .required(l10n.validationErrors.required),
  address_stateAbbreviation: Yup.string()
    .test(
      'length state exact',
      l10n.validationErrors.state.exact,
      val => val && val.length === 2
    )
    .matches(/^[a-zA-Z]+$/, l10n.validationErrors.state.exact)
    .required(l10n.validationErrors.required),
  address_zipCode: Yup.string()
    .matches(/^\d*$/, l10n.validationErrors.zip.invalid)
    .min(5, l10n.validationErrors.zip.min)
    .max(5, l10n.validationErrors.zip.max)
    .required(l10n.validationErrors.required)
}

const fields = {
  ...addressValidations,
  created: Yup.boolean(),
  completed: Yup.boolean(),
  dateSubmitted: Yup.string(),
  [applyFields.applicantBusinessName]: Yup.string()
    .required(l10n.validationErrors.required)
    .max(255, l10n.validationErrors.businessName.max),
  [applyFields.newToFinancing]: Yup.boolean(),
  firstName: Yup.string().required(l10n.validationErrors.required),
  lastName: Yup.string().required(l10n.validationErrors.required),
  services: Yup.object()
    .shape()
    .test(
      'has-service-selected',
      l10n.validationErrors.required,
      hasFieldSelected
    )
    .required(l10n.validationErrors.services.invalid),
  primaryBusiness: Yup.string(),
  email: Yup.string()
    .email(l10n.validationErrors.email.invalid)
    .required(l10n.validationErrors.required),
  phone: Yup.string().when('created', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string()
      .matches(/^\d*$/, l10n.validationErrors.phone.invalid)
      .max(10, l10n.validationErrors.phone.max)
      .min(10, l10n.validationErrors.phone.min)
      .required(l10n.validationErrors.required)
  }),
  loanType: Yup.string(),
  agreeTermsApplicant: Yup.boolean()
    .oneOf([true])
    .required(l10n.validationErrors.required),
  recaptcha: Yup.string(),
  sessionId: Yup.string(),
  tmxSessionId: Yup.string(),
  utmSource: Yup.string(),
  utmMedium: Yup.string(),
  utmCampaign: Yup.string(),
  utmTerm: Yup.string(),
  utmContent: Yup.string(),
  business: {
    ...addressValidations,
    annualSales: Yup.string().required(l10n.validationErrors.required),
    doingBusinessAs: Yup.string().when('showDBA', {
      is: true,
      then: Yup.string().required(l10n.validationErrors.required)
    }),
    name: Yup.string()
      .required(l10n.validationErrors.required)
      .max(255, l10n.validationErrors.businessName.max),
    referralCode: Yup.string().nullable(),
    accountNumber: bankFieldValidator(
      Yup.string().required(l10n.validationErrors.required)
    ),
    routingNumber: bankFieldValidator(
      Yup.string()
        .ensure()
        .length(ROUTING_NUMBER_LENGTH, l10n.validationErrors.bankAccount.field)
    ),
    accountType: Yup.string().when(['accountNumber', 'routingNumber'], {
      is: (accountNumber, routingNumber) => accountNumber || routingNumber,
      then: Yup.string().required(l10n.validationErrors.required),
      otherwise: Yup.string().when('hasNoBankAccount', {
        is: hasNoBankAccount => hasNoBankAccount !== true,
        then: Yup.string()
          .ensure()
          .length(0, l10n.validationErrors.bankAccount.blank)
      })
    }),
    hasNoBankAccount: Yup.bool(),
    stateOfOperation: Yup.object(
      Object.values(states).reduce((result, { abbreviation }) => {
        // eslint-disable-next-line no-param-reassign
        result[abbreviation] = Yup.object().shape({
          hasNoLicenses: Yup.boolean(),
          licenses: Yup.array().when('hasNoLicenses', {
            is: false,
            then: Yup.array().of(
              Yup.string().test(
                'test',
                l10n.validationErrors.required,
                function isLicenseValid(value) {
                  const index = this.parent.indexOf(value)
                  // only the first license number is required for each state
                  return (
                    (index === 0 &&
                      this.parent.indexOf(value) === 0 &&
                      (value && value.length > 0)) ||
                    index > 0
                  )
                }
              )
            ),
            otherwise: Yup.array().of(Yup.string().nullable())
          })
        })

        return result
      }, {})
    ),
    primaryStateOfOperation: Yup.string().required(
      l10n.validationErrors.required
    ),
    onlyInPrimaryState: Yup.boolean(),
    additionalStateOfOperations: Yup.object(
      Object.values(states).reduce((result, { abbreviation }) => {
        // eslint-disable-next-line no-param-reassign
        result[abbreviation] = Yup.object().shape({
          hasNoLicenses: Yup.boolean(),
          licenses: Yup.array().of(Yup.string())
        })

        return result
      }, {})
    ),
    hasNoEIN: Yup.boolean(),
    taxpayerIdentificationNumber: Yup.string().when('hasNoEIN', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .matches(/^\d{9}$/, l10n.validationErrors.ein.invalid)
        .required(l10n.validationErrors.required)
        .typeError(l10n.validationErrors.required)
    }),
    hasNoWebsiteUrl: Yup.boolean(),
    websiteUrl: Yup.string().when('hasNoWebsiteUrl', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .matches(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,24}(:[0-9]{1,5})?(\/.*)?$/i,
          {
            message: l10n.validationErrors.website.invalid,
            excludeEmptyString: true
          }
        )
        .required(l10n.validationErrors.required)
        .typeError(l10n.validationErrors.required)
    }),
    uploadedFiles: Yup.object().nullable()
  },
  principal: {
    ...addressValidations,
    hasNoSSN: Yup.boolean(),
    dateOfBirth: Yup.date().when('hasNoSSN', {
      is: true,
      then: Yup.date().nullable(),
      otherwise: Yup.date()
        .max(
          getDateDiff(PRINCIPAL_MIN_AGE),
          l10n.apply.section3.principalDateOfBirthMaxDate
        )
        .min(
          getDateDiff(PRINCIPAL_MAX_AGE),
          l10n.apply.section3.principalDateOfBirthMinDate
        )
        .transform((currentValue, originalValue) => {
          const parseFormat = 'yyyy-MM-dd'

          // https://github.com/jquense/yup#mixedtransformcurrentvalue-any-originalvalue-any--any-schema
          // the default coercion failed so lets try it with date-fns instead
          const parsedDate = parse(originalValue, parseFormat, new Date())

          // if its valid return the date object, otherwise return an `InvalidDate`
          return isValid(parsedDate) ? toDate(parsedDate) : new Date('')
        })
        .typeError(l10n.validationErrors.dateOfBirth.invalid)
        .required(l10n.validationErrors.required)
    }),
    sameAsApplicant: Yup.boolean(),
    firstName: Yup.string().when('sameAsApplicant', {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required(l10n.validationErrors.required)
    }),
    lastName: Yup.string().when('sameAsApplicant', {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string().required(l10n.validationErrors.required)
    }),
    email: Yup.string().when('sameAsApplicant', {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .email(l10n.validationErrors.email.invalid)
        .required(l10n.validationErrors.required)
    }),
    phone: Yup.string().when('sameAsApplicant', {
      is: true,
      then: Yup.string(),
      otherwise: Yup.string()
        .matches(/^\d*$/, l10n.validationErrors.phone.invalid)
        .max(10, l10n.validationErrors.phone.max)
        .min(10, l10n.validationErrors.phone.min)
        .required(l10n.validationErrors.required)
    }),
    ssn: Yup.string().when('hasNoSSN', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .max(9, l10n.validationErrors.ssn.max)
        .min(9, l10n.validationErrors.ssn.min)
        .matches(/^\d*$/, l10n.validationErrors.ssn.invalid)
        .required(l10n.validationErrors.required)
        .typeError(l10n.validationErrors.required)
    })
  }
}

export const fieldContexts = {
  [applyFields.phone]: {
    created: applyFields.created
  },
  [applyFields.businessTaxpayerIdentificationNumber]: {
    hasNoEIN: applyFields.businessHasNoEIN
  },
  [applyFields.businessWebsiteUrl]: {
    hasNoWebsiteUrl: applyFields.businessHasNoWebsiteUrl
  },
  [applyFields.businessBankAccountAccountNumber]: {
    hasNoBankAccount: applyFields.businessHasNoBankAccount
  },
  [applyFields.businessBankAccountRoutingNumber]: {
    hasNoBankAccount: applyFields.businessHasNoBankAccount
  },
  [applyFields.businessBankAccountAccountType]: {
    hasNoBankAccount: applyFields.businessHasNoBankAccount
  },
  [applyFields.principalFirstName]: {
    sameAsApplicant: applyFields.principalSameAsApplicant
  },
  [applyFields.principalLastName]: {
    sameAsApplicant: applyFields.principalSameAsApplicant
  },
  [applyFields.principalSSN]: {
    hasNoSSN: applyFields.principalHasNoSSN
  },
  [applyFields.principalDateOfBirth]: {
    hasNoSSN: applyFields.principalHasNoSSN
  },
  [applyFields.principalEmail]: {
    sameAsApplicant: applyFields.principalSameAsApplicant
  },
  [applyFields.principalPhone]: {
    sameAsApplicant: applyFields.principalSameAsApplicant
  }
}

const toYupShape = obj => {
  const shape = Object.entries(obj).reduce((result, [key, value]) => {
    /* eslint-disable no-param-reassign */
    if (Yup.isSchema(value)) {
      result[key] = value
    } else {
      result[key] = toYupShape(value)
    }

    return result
  }, {})

  return Yup.object().shape(shape)
}

export const createFieldContext = (fieldName, applicant) => {
  const context = fieldContexts[fieldName]

  if (!context) return {}

  return Object.entries(context).reduce(
    (result, [relatedName, relatedFieldName]) => {
      result[relatedName] = get(applicant, relatedFieldName)

      return result
    },
    {}
  )
}

export const createValidation = fieldNames => {
  const shape = pick(fields, fieldNames)
  return toYupShape(shape)
}

export default fields
