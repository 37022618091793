import { ApplicantId } from '../../types'

export const fileUpload = (applicantId: ApplicantId) =>
  `/applicant/${applicantId}/content`

export const fileUploadUrl = (contentUUID: string): string =>
  `/applicant/${contentUUID}/content`

export const getApplicant = (applicantId: ApplicantId) =>
  `/applicant/${applicantId}`

export const postApplicant = () => '/applicant'

export const patchApplicant = (applicantId: ApplicantId) =>
  `/applicant/${applicantId}`
