import featureFlags from 'constants/enums/featureFlags'

const defaultPath = '/'
const DOT_SEPARATOR = '.'

const getSubdomain = () => {
  const { hostname } = window.location
  const domainParts = hostname.split(DOT_SEPARATOR)
  return domainParts[0]
}

const getRedirectDomain = () => {
  const { hostname } = window.location
  const domainParts = hostname.split(DOT_SEPARATOR)
  domainParts.shift()
  const redirectDomain = domainParts.join(DOT_SEPARATOR)
  
  return redirectDomain
}

const redirectValueForSubdomain = flags => {
  const data = flags[featureFlags.redirectSubdomains] || {}
  const value = getSubdomain()
  if (value) {
    return data[value]
  }
}

export const pathForSubdomain = (flags: { [flag: string]: any }) => {
  const { protocol, pathname: path } = window.location
  const flagValue = redirectValueForSubdomain(flags)
  if (flagValue) {
    const redirectPath = flagValue[path] || flagValue[defaultPath]
    const newPath = `${protocol}//${getRedirectDomain()}${redirectPath}`
    return newPath
  }
}
