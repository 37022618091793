import axios from 'axios' // https://laravel-news.com/building-flexible-axios-clients
import { get } from 'lodash'
import {
  getApiHost,
  getCurrentEnvironment,
  getApiUrlDeprecated_AllFlagsOff
} from 'properties/properties'
import { currentEnvironment } from '../../properties/properties'

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = flags => {
  const baseUrl = getApiHost(getCurrentEnvironment(), flags)
  const options = {
    baseURL: baseUrl,
    headers: { 'Content-type': 'application/json; charset=UTF-8' }
  }
  const client = axios.create(options)

  return client
}

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  get(url, conf = {}, flags) {
    return getClient(flags).get(url, conf)
  },

  post(url, data = {}, conf = {}, flags) {
    return getClient(flags).post(url, data, conf)
  },

  patch(url, data = {}, conf = {}, flags) {
    return getClient(flags).patch(url, data, conf)
  },

  baseURL(flags, defaultBaseUrl = getApiUrlDeprecated_AllFlagsOff(currentEnvironment)) {
    return get(
      getClient(flags),
      'defaults.baseURL',
      defaultBaseUrl
    )
  }
}
