import React from 'react'
import withContainers from 'store/withContainers'
import { SnackbarProvider } from 'notistack'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import { get, flow, noop, has, isEqual, isEmpty, isUndefined } from 'lodash'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { parse } from 'query-string'
import sessionStorageItems from 'constants/enums/sessionStorageItems'
import LazyLoadBackgroundImage from 'components/shared/LazyLoadBackgroundImage'
import Loading from 'components/shared/Loading'
import basePaths from 'constants/enums/basePaths'
import errors from 'constants/enums/errors'
import { MOBILE_WIDTH } from 'properties/properties'
import OriginalDesktopBackgroundImage from 'images/background-desktop.jpg'
import OriginalMobileBackgroundImage from 'images/background-mobile.jpg'
import TrailerBackgroundImage from 'images/background-desktop-trailer.jpg'
import HandshakeBackgroundImage from 'images/background-desktop-handshake.jpg'
import ZeroBackgroundImage from 'images/background-zero.jpg'
import ErrorMessage from 'components/shared/ErrorMessage'
import featureFlags from 'constants/enums/featureFlags'
import getDefaultTheme from 'themes/default'
import { isSolarZero, getReferralCode } from 'utils/sessionHelper'
import { slugToPath } from 'utils/routeHelper'
import stageSlugs from 'constants/enums/stageSlugs'
import ApplicantContainer from 'store/ApplicantContainer'
import routes, { Start, Error, Route as RouteInterface } from 'config/routes'
import { BackgroundImageOptions, Flags } from 'types'

const { start } = basePaths

const MaintenanceWrapper = withLDConsumer()(({ flags }) => {
  const maintenanceMode: boolean = get(
    flags,
    featureFlags.maintenanceMode,
    false
  )

  if (isEmpty(flags)) return <Loading />

  if (maintenanceMode) {
    return <Error type={errors.maintenanceMode} />
  }

  return (
    <Switch>
      <Route exact path={start} component={Start} />

      {routes
        .filter(
          (route: RouteInterface): Array<RouteInterface> =>
            !has(route, 'featureFlag') ||
            (has(route, 'featureFlag') &&
              !isUndefined(route.featureFlag) &&
              !isUndefined(flags) &&
              flags[route.featureFlag])
        )
        .map((route: RouteInterface) => (
          <Route exact key={route.path} {...route} />
        ))}

      <Route component={Error} key={errors.code404} />
    </Switch>
  )
})

const skippablePathsForSolar = [
  basePaths.start,
  `${basePaths.apply}/${stageSlugs.intro}`
]

interface Props extends RouteComponentProps {
  flags: Flags
  location: any
  applicantContainer: {
    setInitialSponsorCode: Function
  }
  history: any
}
interface State {
  currentTheme: any | null
  backgroundImage: any | null
}

const getBackgroundImage = flags => {
  const screenWidth = window.innerWidth
  const backgroundImageOption: BackgroundImageOptions =
    flags[featureFlags.backgroundImageOption]

  switch (backgroundImageOption) {
    case BackgroundImageOptions.Trailer:
      return TrailerBackgroundImage
    case BackgroundImageOptions.Handshake:
      return HandshakeBackgroundImage
    case BackgroundImageOptions.Original:
      return screenWidth <= MOBILE_WIDTH
        ? OriginalMobileBackgroundImage
        : OriginalDesktopBackgroundImage
    default:
      return null
  }
}

const getBackgroundImageStyle = flags => {
  const screenWidth = window.innerWidth
  const backgroundImageOption: BackgroundImageOptions =
    flags[featureFlags.backgroundImageOption]

  switch (backgroundImageOption) {
    case BackgroundImageOptions.Trailer:
      return {}
    case BackgroundImageOptions.Handshake:
      return screenWidth <= MOBILE_WIDTH
        ? {
            backgroundSize: 'cover'
          }
        : {
            backgroundPositionX: '50%',
            backgroundPositionY: '55%',
            backgroundSize: '110% auto'
          }
    case BackgroundImageOptions.Original:
    default:
      return {}
  }
}

class App extends React.Component<Props, State> {
  state = {
    currentTheme: getDefaultTheme(this.props.flags, isSolarZero()),
    backgroundImage: getBackgroundImage(this.props.flags),
    backgroundImageStyle: getBackgroundImageStyle(this.props.flags)
  }

  unlisten = noop

  componentDidMount() {
    const { history } = this.props
    if (window) {
      window.addEventListener('resize', this.handleWindowResize)
    }
    this.handleWindowResize()
    this.unlisten = history.listen(this.handleRouteChange)
    this.setCurrentTheme()

    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content
    } = parse(history.location.search)

    const utmParamsSerialized = [
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content
    ].join(',')
    sessionStorage.setItem(
      sessionStorageItems.UTM_PARAMETERS,
      utmParamsSerialized
    )
  }

  async componentDidUpdate(prevProps) {
    const {
      flags,
      history,
      history: {
        location: { pathname }
      },
      applicantContainer
    } = this.props

    const { flags: prevFlags } = prevProps
    const ref = getReferralCode(history.location.search)

    if (ref) await applicantContainer.setInitialSponsorCode(ref)

    if (!isEqual(prevFlags, flags)) {
      if (isSolarZero() && skippablePathsForSolar.includes(pathname)) {
        history.push(slugToPath(stageSlugs.applicant))
      }
      this.setCurrentTheme()
      this.handleWindowResize()
    }
  }

  componentWillUnmount() {
    this.unlisten()
    if (window) {
      window.removeEventListener('resize', this.handleWindowResize)
    }
  }

  handleWindowResize = () => {
    const { flags } = this.props
    this.setState(state => ({
      ...state,
      backgroundImage: getBackgroundImage(flags),
      backgroundImageStyle: getBackgroundImageStyle(flags)
    }))
  }

  isSolarZeroOrUrlIsZero = () => {
    const {
      location: { pathname }
    } = this.props

    return isSolarZero() || pathname === basePaths.zero
  }

  setCurrentTheme = () => {
    const { flags } = this.props
    const currentTheme = getDefaultTheme(flags, this.isSolarZeroOrUrlIsZero())

    this.setState({
      currentTheme
    })
  }

  handleRouteChange = () => {
    //@ts-ignore
    if (window.piTracker) {
      //@ts-ignore
      window.piTracker()
    }
  }

  render() {
    const { currentTheme, backgroundImage, backgroundImageStyle } = this.state

    return (
      <MuiThemeProvider theme={currentTheme}>
        <SnackbarProvider maxSnack={5}>
          <>
            <LazyLoadBackgroundImage
              classes={{ container: 'app-container' }}
              style={{ ...backgroundImageStyle }}
              src={
                this.isSolarZeroOrUrlIsZero()
                  ? ZeroBackgroundImage
                  : backgroundImage
              }
            >
              <MaintenanceWrapper />
            </LazyLoadBackgroundImage>
            <ErrorMessage />
          </>
        </SnackbarProvider>
      </MuiThemeProvider>
    )
  }
}

export default flow(
  withRouter,
  withLDConsumer(),
  withContainers([ApplicantContainer])
)(App)
