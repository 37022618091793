import invert from 'lodash/invert'
import getSfSchema from 'constants/enums/sfSchema'
import { Flags } from 'types'

const invertFields = (flags: Flags = {}): Object => invert(getSfSchema())

const mapErrorHelper = (error: any, flags: Flags = {}): any => {
  const mappedError: any = {}
  if (error.errors !== undefined) {
    error.errors.forEach(errorValue => {
      if (errorValue.fields[0]) {
        const key = invertFields(flags)[`applicant.${errorValue.fields[0]}`]
        if (!key) return
        mappedError.errors = mappedError.errors || {}
        mappedError.errors[key] = errorValue.message
      } else {
        mappedError.error = errorValue.message
      }
    })
  }
  return mappedError
}

export default mapErrorHelper
