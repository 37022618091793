import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)

export const isMobile = (): boolean => !!md.mobile()

export const isPhone = (): boolean => !!md.phone()

export const isStandalone = (): boolean => {
  // iOS
  // @ts-ignore
  if (window.navigator.standalone) return true

  // Android
  return (
    window.matchMedia && window.matchMedia('(display-mode: standalone)').matches
  )
}
