import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { pathForSubdomain } from 'utils/redirectSubdomainsUtil'

const RedirectSubdomains = withLDConsumer()(({ flags = {} }) => {
    const newPath = pathForSubdomain(flags)
    if (newPath) {
         window.location.assign(newPath)
     }
    return null
})

export default RedirectSubdomains