import difference from 'lodash/difference'
import flatMap from 'lodash/flatMap'
import union from 'lodash/union'
import { Container } from 'unstated'
import getStages from 'config/desktopStages'
import applyFields from 'constants/enums/applyFields'
import getPhoneStages from 'config/phoneStages'
import stageTypes from 'constants/enums/stageTypes'
import { isPhone } from 'utils/mobileHelper'
import { nextCard, prevCard } from 'utils/routeHelper'
import {
  Stage,
  Slug,
  ApplicantId,
  Flags,
  Fields,
  HiddenFields,
  Section
} from 'types'

const relatedHiddenFields: any = {
  [applyFields.businessTaxpayerIdentificationNumber]: [
    applyFields.businessHasNoEIN
  ],
  [applyFields.businessWebsiteUrl]: [applyFields.businessHasNoWebsiteUrl],
  [applyFields.principalSSN]: [applyFields.principalHasNoSSN]
}

const addSteps = (stages: Array<Stage>): Array<Stage> => {
  let step: number = 0

  return stages.map(stage => {
    if (stage.type === stageTypes.intro) {
      step += 1
    }

    return {
      ...stage,
      step
    }
  })
}

const filterHiddenFields = (
  stages: Array<Stage>,
  hiddenFields: Array<string> = []
): Array<Stage> =>
  stages
    // remove hidden-fields
    .map(stage => {
      let { fields } = stage

      if (fields) {
        fields = fields.filter(field => !hiddenFields.includes(field))
      }

      return {
        ...stage,
        fields
      }
    })
    // remove form stages without fields
    .filter(
      stage =>
        stage.type !== stageTypes.form ||
        (stage.fields && !!stage.fields.length)
    )
    // remove intro stages without screens
    .filter((stage, index, filteredStages) => {
      const nextStage = filteredStages[index + 1]

      return (
        stage.type !== stageTypes.intro ||
        !nextStage ||
        ![stageTypes.intro, stageTypes.review].includes(nextStage.type)
      )
    })

interface State {
  hiddenFields: Array<string>
  permanentHiddenFields: Array<string>
  loading: boolean
}

export default class StagesContainer extends Container<State> {
  state = {
    hiddenFields: [],
    // @todo: change permanent to an array of IDs
    // { [field: string]: (ids: string[]) }
    permanentHiddenFields: [],
    loading: true
  }

  nextCard = (
    slug: Slug,
    applicantId: ApplicantId | null = null,
    flags: Flags = {},
    slugToSkip: Slug | null = null
  ): string => {
    return nextCard(this.getStages(flags), slug, applicantId, slugToSkip)
  }

  prevCard = (
    slug: Slug,
    applicantId: ApplicantId | null,
    flags: Flags
  ): string => prevCard(this.getStages(flags), slug, applicantId)

  getStages = (flags: Flags): Array<Stage> => {
    const hiddenFields: HiddenFields = this.getHiddenFields()
    const config: Array<Stage> = this.getStagesConfig(flags || {})

    const filteredStages: Array<Stage> = filterHiddenFields(
      config,
      hiddenFields
    )

    return addSteps(filteredStages)
  }

  getStagesConfig = (flags: Flags = {}): Array<Stage> =>
    isPhone() ? getPhoneStages(flags) : getStages(flags)

  clearHiddenFields = async (permanent: boolean = false): Promise<void> => {
    const newState = {
      hiddenFields: []
    }

    if (permanent) {
      // @ts-ignore
      newState.permanentHiddenFields = []
    }

    await this.setState(newState)
  }

  getHiddenFields = (): HiddenFields => {
    const { hiddenFields, permanentHiddenFields } = this.state

    return union(hiddenFields, permanentHiddenFields)
  }

  removeHiddenFields = async (
    fields: HiddenFields = [],
    permanent: boolean = false
  ): Promise<void> => {
    await this.setState(prevState => {
      const newState: any = {
        hiddenFields: difference(prevState.hiddenFields, fields)
      }

      if (permanent) {
        newState.permanentHiddenFields = difference(
          prevState.permanentHiddenFields,
          fields
        )
      }

      return newState
    })
  }

  setHiddenFields = async (fields: Fields = [], permanent: boolean = false) => {
    const stateField: string = permanent
      ? 'permanentHiddenFields'
      : 'hiddenFields'

    const allFields: Fields = flatMap(fields, field => {
      const relatedFields: Fields = relatedHiddenFields[field] || []

      return [field, ...relatedFields]
    })

    await this.setState({
      [stateField]: allFields
    })
  }

  findStage = (
    isMobile: boolean = false,
    section: Section,
    flags: Flags = {}
  ): Stage | null => {
    if (!section) return null

    if (isMobile) {
      return getPhoneStages(flags).find(stage => stage.slug === section) || null
    }
    return getStages(flags).find(stage => stage.slug === section) || null
  }
}

export const stagesContainer = new StagesContainer()
