import basePaths from 'constants/enums/basePaths'
import Loadable from 'react-loadable'
import Loading from 'components/shared/Loading'

const { apply, error, zero, loadingP2X } = basePaths

export const Start = Loadable({
  loader: () => import('screens/start/Start'),
  loading: Loading
})

export const Apply = Loadable({
  loader: () => import('screens/apply/Apply'),
  loading: Loading
})

export const Error = Loadable({
  loader: () => import('screens/error/Error'),
  loading: Loading
})

export const Zero = Loadable({
  loader: () => import('screens/zero/Zero'),
  loading: Loading
})

export const LoadingP2X = Loadable({
  loader: () => import('screens/apply/LoadingP2X'),
  loading: Loading
})

export interface Route {
  path: string
  component: any
  featureFlag?: string
}

const routes: Array<Route> = [
  {
    path: `${apply}/:applicantId/:section`,
    component: Apply
  },
  {
    path: `${apply}/:section?`,
    component: Apply
  },
  {
    path: `${error}/:type`,
    component: Error
  },
  {
    path: `${zero}`,
    component: Zero
  },
  {
    path: `${loadingP2X}`,
    component: LoadingP2X
  }
]

export default routes
