import { parseDomain, ParseResultType, ParseResultListed } from 'parse-domain'
import isEmpty from 'lodash/isEmpty'
import featureFlags from 'constants/enums/featureFlags'
import environmentsEnum from 'constants/enums/environments'
import { getProjectTypes } from 'constants/enums/projectTypes'

const API_PROTOCOL = 'https:'

const getListedFQDN = ({ domain, topLevelDomains }: ParseResultListed) =>
  `${domain}.${topLevelDomains.join('.')}`

const domainsByEnvs = {
  'preview-mosaic.com': environmentsEnum.testing,
  'prod-mosaic.com': environmentsEnum.e2e,
  'joinmosaic.com': environmentsEnum.production
}

const fullDomainEnvs = {
  'qa.partner.prod-mosaic.com': environmentsEnum.qa,
  'qa.partner.joinmosaic.com': environmentsEnum.qa,
  'partner.qa.joinmosaic.com': environmentsEnum.qa,
  'e2e.partner.joinmosaic.com': environmentsEnum.e2e,
  'partner.e2e.joinmosaic.com': environmentsEnum.e2e,
  'sandbox.partner.joinmosaic.com': environmentsEnum.sandbox,
  'partner.sandbox.joinmosaic.com': environmentsEnum.sandbox
}

const defaultEnv = environmentsEnum.testing

export const getEnvByHost = (hostname = window.location.hostname) => {
  const parseResult = parseDomain(hostname)
  if (parseResult.type === ParseResultType.Listed) {
    if (fullDomainEnvs[parseResult.hostname]) {
      return fullDomainEnvs[parseResult.hostname]
    }
    const host = getListedFQDN(parseResult)
    return domainsByEnvs[host] || defaultEnv
  }
  return defaultEnv
}

// @deprecated
// Kept for posterity if you turn off both salesforce url flags.
// But please don't do that.
export const getApiUrlDeprecated_AllFlagsOff = env => {
  switch (env) {
    case environmentsEnum.production:
      return 'joinmosaic.secure.force.com/partnerform/services/apexrest/v2'
    case environmentsEnum.e2e:
      return 'joinmosaic--full.sandbox.my.salesforce-sites.com/partnerform/services/apexrest/v2'
    case environmentsEnum.qa:
      return 'joinmosaic--qa.sandbox.my.salesforce-sites.com/partnerform/services/apexrest/v2'
    case environmentsEnum.sandbox:
      return 'joinmosaic--sandbox.sandbox.my.salesforce-sites.com/partnerform/services/apexrest/v2'
    default:
      console.error(`No environment found for env: ${env}`)
      return 'joinmosaic--qa.sandbox.my.salesforce-sites.com/partnerform/services/apexrest/v2'
  }
}

export const getCurrentEnvironment = () =>
  getEnvByHost() || environmentsEnum.testing

export const currentEnvironment = getCurrentEnvironment()

export const isProduction = currentEnvironment === environmentsEnum.production

// Allow team to use custom endpoints via query string in non-production environments
// @ts-ignore
const params = new URLSearchParams(window.location.search)
const endpoint = !isProduction ? params.get('endpoint') : undefined

export const defaultApiHost =
'https://full-joinmosaic.cs197.force.com/partnerform/services/apexrest/v2'


export const getApiHost = (environment, flags) => {
  const apiHost = endpoint || process.env.REACT_APP_API_HOST

  if (!apiHost && flags) {
    if (flags[featureFlags.salesforceUrl]) {
      return flags[featureFlags.salesforceUrl]
    } else {
      return `${API_PROTOCOL}//${getApiUrlDeprecated_AllFlagsOff(environment)}`
    }
  }
  return apiHost
}

export const CURRENT_ENVIRONMENT = currentEnvironment

export const DOCUMENT_TITLE = {
  appName: 'Mosaic Partner Sign-up',
  appNameShort: 'Mosaic',
  apply: 'Apply'
}
export const SUPPORT_EMAIL = 'partner@joinmosaic.com'
export const APPLICATION_EMAIL = 'application@joinmosaic.com'
export const DOLLAR_PREFIX = '$'
export const REPROFILING_DELAY = 500
export const SAVE_DELAY = 1000
export const PRINCIPAL_MIN_AGE = 15
export const PRINCIPAL_MAX_AGE = 130
export const CIRCULAR_PROGRESS_SIZE = 16
export const SOLAR_ZERO_DEFAULT_PROJECT = () => ({
  [getProjectTypes().solar]: true
})
export const PARTNER_HASH_LENGTH = 24
export const EMPTY_STRING = ''

export const MOBILE_WIDTH = 600
