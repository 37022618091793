export default {
  created: 'created',
  completed: 'completed',
  dateSubmitted: 'dateSubmitted',
  // Applicant
  applicantBusinessName: 'businessName',
  newToFinancing: 'newToFinancing',
  firstName: 'firstName',
  lastName: 'lastName',
  phone: 'phone',
  email: 'email',
  agreeTermsApplicant: 'agreeTermsApplicant',
  recaptcha: 'recaptcha',
  services: 'services',
  primaryBusiness: 'primaryBusiness',
  loanType: 'loanType',
  address_street1: 'address_street1',
  address_city: 'address_city',
  address_stateAbbreviation: 'address_stateAbbreviation',
  address_zipCode: 'address_zipCode',
  // Business
  sponsorCode: 'business.referralCode',
  businessName: 'business.name',
  businessDoingBusinessAs: 'business.doingBusinessAs',
  businessTaxpayerIdentificationNumber: 'business.taxpayerIdentificationNumber',
  businessUploadedFiles: 'business.uploadedFiles',
  businessHasNoEIN: 'business.hasNoEIN',
  businessStateOfOperation: 'business.stateOfOperation',
  businessPrimaryStateOfOperation: 'business.primaryStateOfOperation',
  businessAddress_street1: 'business.address_street1',
  businessAddress_city: 'business.address_city',
  businessAddress_stateAbbreviation: 'business.address_stateAbbreviation',
  businessAddress_zipCode: 'business.address_zipCode',
  businessAnnualSales: 'business.annualSales',
  businessWebsiteUrl: 'business.websiteUrl',
  businessHasNoWebsiteUrl: 'business.hasNoWebsiteUrl',
  businessBankAccountAccountNumber: 'business.accountNumber',
  businessBankAccountRoutingNumber: 'business.routingNumber',
  businessBankAccountAccountType: 'business.accountType',
  businessHasNoBankAccount: 'business.hasNoBankAccount',
  // Principal
  principalSameAsApplicant: 'principal.sameAsApplicant',
  principalFirstName: 'principal.firstName',
  principalLastName: 'principal.lastName',
  principalEmail: 'principal.email',
  principalPhone: 'principal.phone',
  principalSSN: 'principal.ssn',
  principalHasNoSSN: 'principal.hasNoSSN',
  principalDateOfBirth: 'principal.dateOfBirth',
  principalAddressStreet: 'principal.address_street1',
  principalAddressCity: 'principal.address_city',
  principalAddressStateAbbreviation: 'principal.address_stateAbbreviation',
  principalAddressZipCode: 'principal.address_zipCode',
  sessionId: 'sessionId',
  tmxSessionId: 'tmxSessionId',
  // UTM Campaign Query Parameters
  utmSource: 'utmSource',
  utmMedium: 'utmMedium',
  utmCampaign: 'utmCampaign',
  utmTerm: 'utmTerm',
  utmContent: 'utmContent'
}
