import mapKeys from 'lodash/mapKeys'
import get from 'lodash/get'
import set from 'lodash/set'
import keys from 'lodash/keys'
import applyFields from 'constants/enums/applyFields'

interface ContractorLicenseMapping {
  license: string
  state: string
  isPrimary: string
  hasNoLicenses: string
}

const sfContractorLicenses: ContractorLicenseMapping = {
  license: 'mg_License_Number__c',
  state: 'mg_State__c',
  isPrimary: 'mg_IsPrimary__c',
  hasNoLicenses: 'mg_No_License_Number__c'
}

const transformator = {
  [applyFields.services]: value =>
    value.filter(data => data !== undefined).join(';'),
  [applyFields.businessStateOfOperation]: statesOfOperations => {
    const newStatesOfOperations = statesOfOperations.map(statesOfOperation => {
      const thisState = {}
      mapKeys(sfContractorLicenses, (contractorSource, previousSource) => {
        const value = get(statesOfOperation, previousSource)
        set(thisState, contractorSource, value)
      })
      return thisState
    })
    return newStatesOfOperations
  }
}

const transform = (key, value) =>
  transformator[key] === undefined ? value : transformator[key](value)

const mapHelper = (data, schema) => {
  const mapped = {}
  keys(schema).forEach(defaultSource => {
    const value = get(data, defaultSource)
    if (value === undefined) return
    set(mapped, schema[defaultSource], transform(defaultSource, value))
  })

  return mapped
}

export default mapHelper
