import { Container } from 'unstated'
import sessionStorageItems from 'constants/enums/sessionStorageItems'

interface State {
  target: string | null
}

export default class SessionContainer extends Container<State> {
  // The target was added to make it flexible in case that we need to use with different user targets (besides SOLAR_ZERO_TARGETING )
  state = {
    target: null
  }

  setTarget = (target: string | null = null): void => {
    this.setState({ target })
    sessionStorage.setItem(sessionStorageItems.SOLAR_ZERO_TARGETING, 'true')
  }
}

export const sessionContainer = new SessionContainer()
